import React from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import { ColorRing } from 'react-loader-spinner'
const TopLinearProgress = () => {
  const classes = makeStyles(() => {
    return {
      loader:{
        display:'none', 
      }
    };
  })();

  return (
    <>
      <div id='ctn_loader'  className={classes.loader}>
        <div id="loader">
        <ColorRing
            visible={true}
            height="240"
            width="240"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['orange', 'orange', 'orange', 'orange', 'orange']}
          />
        </div> 
      </div> 
  </>);
};

export default TopLinearProgress;

export const showProgress = () => {
  const loader = document.querySelector('#ctn_loader'); 
  loader && (loader.style.display = 'unset'); 
};

export const hideProgress = () => {
  const loader = document.querySelector('#ctn_loader'); 
  loader && (loader.style.display = 'none'); 
};
