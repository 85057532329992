import { SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE, SIGN_OUT } from 'stores/securityStore';
import storage from 'helpers/storage';
import axios, { SIGN_IN_PATH,WAREHOUSES, errorMessage } from 'myAxios';
import { showProgress, hideProgress } from 'utils/components/TopLinearProgress'; 

export const signIn = ({ phoneNumber, code ,skipSendCode }) => { 
  return async (dispatch) => {
    showProgress();
    dispatch({ event: SIGN_IN_REQUEST });
    try { 
      const response = await axios.post(SIGN_IN_PATH, { phoneNumber, code ,skipSendCode });
      if (response.status == 200) {
        storage.setToken(response?.data?.data.accessToken);  
        storage.signIn(response?.data?.data);
        storage.setResponsiblePhone(phoneNumber);
        const responseStoreInfos = await axios.get(WAREHOUSES, {
          params: { signIn: true },
        });
        let storeInfos = responseStoreInfos.data.data;
        /*let storeObject = {};
        storeObject.code = storeInfos.code;
        storeObject.description = storeInfos.description;
        storeObject.latitude = storeInfos.latitude;
        storeObject.longitude = storeInfos.longitude;
        storeObject.localisation = storeInfos.localisation;
        storeObject.mininumToOrder = storeInfos.mininumToOrder;
        storeObject.numberOfTablets = storeInfos.numberOfTablets;
        storeObject.numberOfTerminals = storeInfos.numberOfTerminals;
        storeObject.perimeter = storeInfos.perimeter;
        storeObject.phone = storeInfos.phone;
        storeObject.siteId = storeInfos.siteId;
        storeObject.storeComptoirCode = storeInfos.storeComptoirCode;
        storeObject.warehouseId = storeInfos.warehouseId;
        storeObject.warehouseTypeId = storeInfos.warehouseTypeId;
        console.log('storeObject',storeObject);*/
        storage.setStore(JSON.stringify(storeInfos));
        dispatch({ event: SIGN_IN_SUCCESS});
      } else {
        dispatch({ event: SIGN_IN_FAILURE, payload: errorMessage });
      }
    } catch (err) {
      dispatch({ event: SIGN_IN_FAILURE, payload: err?.response?.data?.error || errorMessage });
    } finally {
      hideProgress();
    }
  };
};

export const signOut = () => (dispatch) => {
  storage.clear();
  dispatch({ event: SIGN_OUT });
};
