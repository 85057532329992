import axios from './index';
import { REFRESH_TOKEN_PATH } from './index';
import storage from 'helpers/storage';
import { Cancel } from 'axios';

let subscribers = [];
let refreshRequested = false;

export default (err) => {
  if (err instanceof Cancel) return;
  const { config: originalRequest, response } = err;
  if (response?.status === 401) {
    if (!refreshRequested) {
      refreshRequested = true;
      axios
        .put(REFRESH_TOKEN_PATH, {
          refreshToken : storage.getRefreshToken(),
        })
        .then((response) => {
          storage.setToken(response.data.data.accessToken);
          storage.setRefreshToken(response.data.data.refreshToken);
          subscribers.map((cb) => cb(response.data.data.token));
          subscribers = [];
          refreshRequested = false;
        })
        .catch(() => {
          storage.clear();
          window.location.reload();
        });
    }
    return new Promise((resolve) => {
      subscribers.push((token) => {
        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        resolve(axios(originalRequest));
      });
    });
  } else {
    return Promise.reject(err);
  }
};
