import React, {} from 'react';
import { trans } from 'itranslator';
import PageTitle from 'utils/components/PageTitle';  
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom'; 
import StoreContainer from 'pages/StoreContainer';
const NewCustomer = () => { 
  const history = useHistory(); 
  const onClick = (e) => {
    e.preventDefault(); 
    history.push('/pre/order/paymentMethods'); 
  };
  return (
    <>
    <StoreContainer> 
      <PageTitle>{trans('NewCustomer')}</PageTitle>
      <Button 
          type="button"
          fullWidth
          variant="outlined"
          color="primary" 
          onClick={onClick}
        >
          {trans('paymentMethods')}
        </Button>
        </StoreContainer>
    </>
  );
};
export default NewCustomer;
