import React, { useState ,useEffect ,useContext } from 'react';
import { Button, Typography} from '@material-ui/core';   
import { useTranslation } from 'react-i18next'; 
import { useHistory } from 'react-router-dom';  
import { OrderContext } from 'pages/OrderContext';     
import storage from 'helpers/storage'; 
import Fade from 'react-reveal/Fade';
const NumBorne = () => { 
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode} = useContext(OrderContext); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;      
  const history = useHistory();   
  const [borne, setBorne] = useState(0);      
  const [enableBorne, setEnableBorne] = useState(false);  
  const [store, setStore] = useState();  
  const [lng, setLng] = useState();  
  const [bornes, setBornes] = useState([]);  
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]); 
  useEffect(() => {  
    setOrderLines();
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null); 
    /*******************/ 
    setStore(storage.getStore());
  }, []);       
  useEffect(() => { 
    if(store){
      let preBornes = [];
      let nbrBornes = store.numberOfTerminals;
      let nbrTablets = store.numberOfTablets;
      if(nbrBornes != null || nbrBornes > 0){
        for(var i=1;i<= nbrBornes;i++){
          preBornes.push(
            {
              id: `terminal-0${i}`,
              code: `Borne 0${i}`,
              code_ar: `Borne ar 0${i}`,
            }
          );
        }
      } 
      if(nbrTablets != null || nbrTablets > 0){
        for(var j=1;j<= nbrTablets;j++){
          preBornes.push(
            {
              id: `tablet-0${j}`,
              code: `Tablet 0${j}`,
              code_ar: `Tablet ar 0${j}`,
            }
          );
        }
      }  
      setBornes(preBornes);
    }
  }, [store]);
  const choixBorne = (id) => {  
    setBorne(id);
    setEnableBorne(true);
  }; 
  const onClick = (e) => { 
    e.preventDefault(); 
    storage.setBorne(borne); 
    history.push('/startPage'); 
  };  
  return (
    <div className={"screen_background screen_background_channel"}> 
    <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
        <Typography component="h1" variant="h5">
          {trans('choose_terminal_title')}
        </Typography>
        <div className={"ctn_channels"}>   
        {bornes?.map((item) => (
          <div className={(borne == item?.id ) ? "channel active" : "channel"} onClick={() => choixBorne(item?.id)} key={item?.id}>
            <p>{lng == "ar" ? item?.code :  item?.code}</p> 
            <img src='https://i.ibb.co/tmS6MZJ/check.png'/> 
          </div>
          ))}
        </div> 
        <Button 
              type="button"
              disabled={!(enableBorne)}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onClick}
            >
              {trans('suivantFr')}
            </Button>
      </div> 
  );
};

export default NumBorne;
