import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationFr } from 'translation.fr';
import { translationAr } from 'translation.ar';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const resources = {
  ar: {
    translation: translationAr
  },
  fr: {
    translation: translationFr
  }
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};

i18n
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: 'fr', 
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });


export default i18n;