import App from 'pages/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import { SecurityProvider } from 'stores/securityStore';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import 'index.css';
import { config } from 'itranslator';
import translation from './translation.fr';
import './i18n';

config({
  source: translation,
});

ReactDOM.render(
  <SnackbarProvider autoHideDuration={3500} maxSnack={3}>
    <SecurityProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <App />
    </MuiPickersUtilsProvider>
    </SecurityProvider>
  </SnackbarProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

