export const translationAr = {
  start: {
    p1: 'مرحبًا بك في شاري',
    p2: 'داخل شاري خارج رابح',
    btn: 'إبدأ هنا',
  },  
  authentication: {
    p1: 'Authentification magasin',
    otp: 'Entrez le code reçu par SMS au', 
  },
  authenticationCustomer: { 
    p1: 'أدخل رقم هاتفك',
    otp: 'أدخل الرمز الذي تلقيته عن طريق الرسالة القصيرة على', 
    error: 'الرمز الذي أدخلته غير صحيح',
  },
  canal: { 
    title: 'حدد نوع النشاط التجاري',
    cgu1: 'من خلال تسجيلك، فإنك تؤكد أنك قد قرأت وقبلت دون تحفظ',
    cgu2 : 'شروط الاستخدام العامة',
  },
  slot: { 
    title: 'في أي وقت تريد استلام طلبك؟',
    today: 'اليوم',
    tomorrow: 'غدا',
    de  : 'من',
    a : 'إلى',
  },
  deliveryMode: {
    p1: ' مرحبًا',
    p2: 'اختر طريقة التسليم ',
    mode11: 'السحب من المتجر',
    mode12: 'سحب بضاعتك من المتجر بعد تأكيد الطلب - الأداء في الحين',
    mode21 : ' التوصيل إلى محلك',
    mode22 : 'نقل بضاعتك إلى متجرك - الأداء عند التسليم',
  },  
  popupMsg: {
    addArticle: 'تمت إضافة السلعة إلى الطلب ',
    minOrder: '{{qte}} الحد الأدنى للطلب هو',
    deleteArticle: 'تمت إزالة السلعة من الطلب',
    qte: 'الكمية المطلوبة غير متوفرة',
    abandonner: ' هل أنت متأكد من عدم متابعة طلبك؟',
    horsZone : 'موقعك خارج نطاق التوصيل ،هل تريد سحب طلبك من المتجر في الحين؟',
    oui : 'نعم',
    non : 'لا',
    NonMerci : 'لا شكرا',
    OuiBienSur : 'نعم ', 
  },
  panier: {
    total: 'المجموع',
    sousTotal: 'الإجمالي',
    taxe : 'الضريبة',
    voirMaCommande  : 'لائحة المشتريات',
    viderPanier : 'افراغ السلة',
    ajouterPanier : 'أضف إلى الطلب',
    abandonner : 'خروج',
    mesCommandes : 'الطلبات',
    continuerAchats : 'متابعة التسوق',
    validerCommande  : 'تأكيد الطلب',
    succesMessageAdd : 'تم وضع طلبكم بنجاح',
    monPanier : 'لائحة المشتريات',
    reference : 'منتوج',
    references : 'المنتوجات',
    aLivrer  : 'توصيل',
    aPrendre  : 'سحب',
    gratuit  : 'مجاني',
    btnDeleteProduct  : 'إزالة المنتج',
    annuler  : 'إلغاء',
    deleteProduct  : 'أنت على وشك إزالة  المنتوج {{designation}}  من الطلب, المرجو التأكيد ',
    panierMsg  : 'يجب عليك تقديم طلب بقيمة {{minimum}} درهم كحد أدنى',
    coutLogistique   : 'تكلفة اللوجستيك',
    remiseLogistique   : 'تخفيضات اللوجستيك ',
    continuerVosAchats   : 'متابعة التسوق',
    totalHt   : 'المجموع بدون رسوم',
    totalNetHt   : 'المجموع بدون رسوم',
    totalTva   : 'ض.ق.م',
    tva   : 'ض.ق.م',
    PopUpdeleteMsg   : 'هل تريد حذف هذا منتوج؟',
    totalTtc   : 'المجموع مع رسوم',
    timbre  : 'رسوم الطابع',
  },
  confirmOrder: { 
    merci : 'شكرا ',
    p1 : 'تم تأكيد طلبكم',
    p2 : 'هل تريد أن تقوم بطلب جديد؟',
    p3 : 'هل تريد أن تقوم بطلب جديد؟',
    NonMerci : 'لا شكرا',
    OuiBienSur : 'نعم', 
  },
  cgu: { 
    p1: 'CHARI s’engage à protéger vos données personnelles sur le web en adhérant au Dahir n° 1-09-15 du 22 safar 1430 (18 février 2009) portant promulgation de la loi n° 09-08 relative à la protection des personnes physiques à l’égard du traitement des données à caractère personnel', 
    p2: 'Toute personne qui communique ses données personnelles, a le droit de :', 
    p3: '- d’accès : consulter vos données personnelles', 
    p4: '- de rectification : compléter et/ou corriger vos données personnelles', 
    p5: '- d’oubli : supprimer vos données selon certains critères', 
    p6: '- de limitation de traitement : restreindre le traitement de vos données pendant une période donnée', 
    p7: '- de portabilité des données : transférer vos données facilement entre les systèmes', 
    p8: '- d’opposition : s’opposer au traitement de vos données à des fins marketing', 
    p9: 'Vous pouvez en connaître davantage sur vos droits d’utilisation des données en lisant la page de La Commission Nationale de contrôle de la protection des Données à caractère Personnel (CNDP)  https://www.cndp.ma/images/lois/Loi-09-08-Fr.pdf', 
    p10: 'Vos données personnelles sont importantes pour CHARI que vous soyez nouveau client ou utilisateur des produits et services ou un client de longue date, vos données personnelles seront gérées dans le cadre de notre politique de protection des données personnelles et des cookies informatiques. Si vous avez des questions n’hésitez pas à nous contacter.', 
    
  },
  free: {
    one: 'حبة',
    many: '{{qte}} حبات', 
    free: 'بالمجان',
  },
  suivant : 'التالي',
  suivantFr : 'Suivant',
  retour : 'الغاء',
  arabe : 'العربية',
  francais : 'Français',
  confirmer  : 'تأكيد',
  choose_terminal_title  : 'C’est quelle borne ?',
  contact_service  : 'يرجى الاتصال بخدمة العملاء',
  unit  : 'وحدة',
  Pièce  : 'حبة',
  PièceFiche  : 'تباع بالحبة',
  Pièces  : 'حبة',
  U  : 'و',
  CAISSE  : 'كرطونة',
  CAISSEFiche  : 'تباع بالكرطونة',
  dh  : 'د.م', 
  dhUnite  : 'درهم للحبة', 
  droitTimbre  : 'مع إحتساب رسوم الطابع',
  code  : 'رمز',
  supprimerArticle  : 'إزالة المنتوج',
  monday  : 'الاثنين',
  repture  : 'غير متوفر',
  promotions  : 'تخفيضات',
  errQte  : 'الكمية المطلوبة غير متوفرة',
  retourr  : 'رجوع',
  orderLineErrQte: 'فقطة {{qte}} متاحة',
  Pce  : 'حبة',
  Cse  : 'كرطونة',
  cmdN: '{{order}} الطلب رقم',
  retrait: 'السحب',
  livraison: 'التوصيل',
  chari: 'شاري',
  address: 'المنطقة الصناعية أولاد صالح، 27182 بوسكورة الدار البيضاء',
  receipt: { 
    article: '({{nbarticle}}) المنتوج', 
    puNet: 'الثمن', 
    qte: 'الكمية', 
    ute: 'الوحدة', 
    total: 'المجموع', 
    thanks: 'شكرا على زيارتكم', 
  },
  articleCaisse  : 'تباع بالكرطونة : {{qte}} حبة',
  palierCaisse  : 'إبتداءا من {{qte}}',
  palierUnt  : 'إبتداءا من {{qte}}',
  articleUnt  : 'تباع بالحبة',
  giftP1  : 'إشتري {{qte}}',
  giftP2  : '= {{qte}} بالمجان',
  giftP3  : '= {{qte}} بالمجان',
  giftSingleArticleP2  : '{{qte}} بالمجان',
  giftSingleArticleP3  : '{{qte}} بالمجان',
  giftP4  : '= هدية',
  Sunday  : 'الأحد',
  Monday  : 'الإثنين',
  Tuesday  : 'الثلاثاء',
  Wednesday  : 'الأربعاء',
  Thursday  : 'الخميس',
  Friday  : 'الجمعة',
  Saturday  : 'السبت',
  minToOrder  : '{{qte}} الحد الأدنى للطلب هو',
};

export default translationAr;
