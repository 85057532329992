import React, {} from 'react';
import Backspace from "@material-ui/icons/Backspace"; 
import PropTypes from 'prop-types';

const NumPad = ({ value , setValue , type , stockAvailable , showMsg,qte ,renderNumPad, setRenderNumPad }) => { 
  if(type == 'qte' && !value && !renderNumPad){
   setValue(qte); 
   setRenderNumPad(true);
 } 
    
  const onChange = (number) => { 
    if(value){
      if(type == 'otp'){
        if(value.length < 6)
        setValue(""+value+number);
      }
      if(type == 'phone'){
        if(value.length < 10)
        setValue(""+value+number);
      }
      if(type == 'qte'){
        console.log('stockAvailable',stockAvailable);
        console.log('value',value);
        if(parseInt(stockAvailable) >= parseInt(""+value+number) ){
          setValue(""+value+number);
        }else{
          showMsg();
        } 
      }
    }else{
      if(type == 'qte'){ 
        if(parseInt(stockAvailable) >= parseInt(number) ){
          setValue(""+number);
        }else{
          showMsg();
        } 
      }else{
        setValue(""+number);
      } 
    }
     
  };
  const del = () => { 
    let newValue = (value == null || value.length == 0) ? null  : (value.substring(0, value.length - 1)); 
    setValue(newValue); 
  };
  const clear = () => {  
    setValue(''); 
  };
  return (
    <div className="pad"> 
      <div className="row">
        <button className="key" onClick={() => onChange(1)}>
          1
        </button>
        <button className="key" onClick={() => onChange(2)}>
          2
        </button>
        <button className="key" onClick={() => onChange(3)}>
          3
        </button>
      </div>
      <div className="row">
        <button className="key" onClick={() => onChange(4)}>
          4
        </button>
        <button className="key" onClick={() => onChange(5)}>
          5
        </button>
        <button className="key" onClick={() => onChange(6)}>
          6
        </button>
      </div>
      <div className="row">
        <button className="key" onClick={() => onChange(7)}>
          7
        </button>
        <button className="key" onClick={() => onChange(8)}>
          8
        </button>
        <button className="key" onClick={() => onChange(9)}>
          9
        </button>
      </div>
      <div className="row"> 
        <button className="key-action key" onClick={clear}>
          C
        </button>
        <button className="key" onClick={() => onChange(0)}>
          0
        </button>
        <button className="key-action key" onClick={del}>
          <Backspace />
        </button>
      </div>
    </div>
  );
};  
NumPad.propTypes = {  
  showMsg: PropTypes.func, 
  setValue: PropTypes.func.isRequired, 
  stockAvailable: PropTypes.number,   
  qte: PropTypes.number,   
  value: PropTypes.number,   
  type: PropTypes.string,   
  renderNumPad: PropTypes.bool,
  setRenderNumPad: PropTypes.func,
};

export default NumPad;
