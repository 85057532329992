import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'; 
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';  

const StockDialog = ({ 
  open,
  onClose, 
}) => {
  
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  return (
    <Dialog className='stock_dialog' open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <> 
      <DialogContent>
        <Typography variant="body1" align="center">
            {trans('errQte')}
          </Typography>
      </DialogContent>
      <DialogActions> 
        <Button 
          color="primary"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onClose(e);
          }}
        >
          {trans('retourr')}
        </Button>
      </DialogActions>
    </>
    </Dialog>
  );
};

StockDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,   
};

export default StockDialog;
