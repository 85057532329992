 export default {  
  getToken: () => localStorage.getItem('token'),
  getRefreshToken: () => localStorage.getItem('refresh_token'),
  isLoggedIn: () => localStorage.getItem('token') !== null,
  setToken: (token) => localStorage.setItem('token', token),
  setRefreshToken: (token) => localStorage.setItem('refresh_token', token), 
  setResponsiblePhone: (responsiblePhone) => localStorage.setItem('responsible_phone', responsiblePhone), 
  getResponsiblePhone: () => localStorage.getItem('responsible_phone'),
  setBorne: (borne) => localStorage.setItem('borne', borne), 
  getBorne: () => localStorage.getItem('borne'),
  setStore: (store) => localStorage.setItem('store', store),  
  getStore: () => {
    try {
      return JSON.parse(localStorage.getItem('store'));
    } catch {
      return undefined;
    }
  },
  clear: () => localStorage.clear(),
  signIn(data){
    this.setRefreshToken(data.refreshToken);  
  },
};
