export const HOME = '/home'; 
export const SIGN_IN_PHONE_PAGE = '/'; 
export const SIGN_IN_OTP_PAGE = '/sign-in/otp/:phone';  
export const CUSTOMER_OTP_PAGE = '/customer/otp/:phone';   
export const CUSTOMER_CHANNEL_PAGE = '/customer/canal/:phone';   
export const BORNE_PAGE = '/borne';   
export const STARTPAGE = '/startPage';  
export const INSERTPHONE = '/pre/order/insertPhone';  
export const DELIVERYMETHODS = '/pre/order/deliveryMethods';  
export const NEWCUSTOMER = '/pre/order/newCustomer';  
export const PAYMENTMETHODS = '/pre/order/paymentMethods';  
export const PANIER = '/panier';  
export const CONFIRMED_ORDER_PAGE = '/panier/confirmedOrder';  
export const CATALOGUE = '/catalogue';  
