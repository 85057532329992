import React, {useEffect, useState ,useContext} from 'react'; 
import StoreContainer from 'pages/StoreContainer'; 
import { useTranslation } from 'react-i18next'; 
import { useHistory } from 'react-router-dom';
import { OrderContext } from 'pages/OrderContext';
import Fade  from 'react-reveal/Fade';
import { orderStatus } from 'consts';
import { CancelToken, ORDERS_PATH } from 'myAxios';
import useAxios, { PUT } from 'hooks/useAxios';   
import SlotDialog from './SlotDialog'; 
import moment from 'moment';
import NumPadQte from 'utils/components/numPadQte/NumPadQte';
import ConfirmationDialog from 'utils/components/ConfirmationDialog';
import StockDialog from './StockDialog';
import storage from 'helpers/storage';
const Panier = () => {  
  const store = storage.getStore(); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const {customer,deliveryMode,setOrderLines,orderLines, orderId,setOrderId, setFooterOrder,setCounter,setOrderConfirmedAt,customerPhone,setSlotReceipt} = useContext(OrderContext);   
  const history = useHistory(); 
  const [idArticlesInPanier, setIdArticlesInPanier] = useState([]);   
  const [idArticlesInPanierQte, setIdArticlesInPanierQte] = useState([]);  
  const [lng, setLng] = useState();    
  const [removeMsg, setRemoveMsg] = useState(false);  
  const [open, setOpen] = useState(false); 
  const [idSlot, setIdSlot] = useState(null);  
  const [desiredDeliveryDate, setDesiredDeliveryDate] = useState('');  
  const [disableButtons, setDisableButtons] = useState(false);  
  const [sousTotalHt, setSousTotalHt] = useState(0);  
  const [coutLogistique, setCoutLogistique] = useState(0);   
  const [tva, setTva] = useState(0);   
  const [timbre, setTimbre] = useState(0);   
  const [totalPanier, setTotalPanier] = useState(0);  
  const [render, setRender] = useState(0); 
  const [renderNumPad, setRenderNumPad] = useState(false); 
  const [qteMsg, setQteMsg] = useState(false);  
  const [minToOrder, setMinToOrder] = useState(false);   
  const [qteToOrder, setQteToOrder] = useState(0); 
  const [numPadQte, setNumPadQte] = useState(false); 
  const [qteNumPad, setQteNumPad] = useState();  
  const [qteNumPadQte, setQteNumPadQte] = useState();  
  const [numPadArticle, setNumPadArticle] = useState();  
  const [openDeleteFromPanier, setOpenDeleteFromPanier] = useState(false);
  const [openErrQte, setOpenErrQte] = useState(false);
  const [listErrQte, setListErrQte] = useState([]);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const [articleIdToDelete, setArticleIdToDelete] = useState(0);
  const [articleIdDesignation, setArticleIdDesignation] = useState('');
  const minimumOrder = store.mininumToOrder;
  useEffect(() => { 
    console.log('orderLines',orderLines);
  }, [orderLines]);  
  useEffect(() => { 
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);
  useEffect(() => { 
    if(articleToDelete){
      setArticleIdToDelete(articleToDelete.articleId);
      lng == "ar" ? setArticleIdDesignation(articleToDelete?.commercialDesignationAr) : setArticleIdDesignation(articleToDelete?.commercialDesignation)
    } 
  }, [articleToDelete]); 
  useEffect(() => { 
    if(articleIdToDelete > 0)
    setOpenDeleteFromPanier(true);
  }, [articleIdToDelete]); 
  useEffect(() => { 
      changeOrder(); 
  }, [render]);    
  useEffect(() => {   
    if(idSlot>0)
    setDisableButtons(true);
  }, [idSlot]);
  const onClickCatalogue = () => { 
    history.push('/catalogue'); 
  }; 
  const { call: callPutOrder, performing: performingPut } = useAxios(PUT, ORDERS_PATH, {
    withProgressBar: true,
  });  
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const showMsg = async (type) => { 
    if(type == "remove"){ 
      setRemoveMsg(true);
      await sleep(3000);
      setRemoveMsg(false);
    }else if(type == 'qte'){
      setQteMsg(true);
      await sleep(3000);
      setQteMsg(false); 
    }else if(type == 'minToOrder'){
      setMinToOrder(true);
      await sleep(3000);
      setMinToOrder(false); 
    }
  };
  //confirm an order
  const confirmOrder = () => { 
    if(totalPanier > store?.mininumToOrder || deliveryMode == "To Go"){
      if((deliveryMode == "To Go") || ((deliveryMode == "To Deliver") && (idSlot > 0))){
        const source = CancelToken.source();
      if (performingPut) {
        return;
      }
      if (orderId) {
        const body = {};  
        body.orderId = orderId;
        body.customerId = customer.customerId; 
        body.language = lng; 
        body.orderStatusId = orderStatus.CONFIRME;
        body.paymentModeId = 100;
        body.desiredDeliveryDate = (deliveryMode == "To Go") ? moment().format('YYYY-MM-DD') : desiredDeliveryDate; 
        body.deliverySlotId = idSlot;
        body.deliveryModeId = (deliveryMode == "To Go") ? 1 : 2;
        body.orderLines = orderLines?.map((element) => {
          const orderLine = {};  
          orderLine.articleId = element?.articleId;
          orderLine.quantity = element?.quantity;
          orderLine.isGift = element?.isGift;
          return orderLine;
        });   
          callPutOrder({ body, source }).then((response) => {
            if (response?.status == 200){  
              let preFooterOrder = {};
              preFooterOrder.totalDiscountedAmountHT = response.data.data.totalDiscountedAmountHT + response.data.data.totalQdt;
              preFooterOrder.totalRebates = response.data.data.totalRebates;
              preFooterOrder.totalQdt = response.data.data.totalQdt;
              preFooterOrder.totalTva = response.data.data.totalTva;
              preFooterOrder.totalAmountTTC = response.data.data.totalAmountTTC; 
              preFooterOrder.totalTimbre = response.data.data.totalTimbre;  
              setFooterOrder(preFooterOrder);
              setCounter(response.data.data.counter);
              setOrderConfirmedAt(response.data.data.confirmedAt);
              history.push('/panier/confirmedOrder');  
              setListErrQte([]);
              setOpenErrQte(false);
            } 
            if (response?.status == 202){ 
              let preErrQte = [];
              if(response.data.data.orderLines){ 
                response.data.data.orderLines.map((orderLine) => {  
                  if(orderLine.quantity > orderLine.availableQuantity){
                    let article = {};
                    article.articleId = orderLine.articleId;   
                    article.quantity = orderLine.quantity; 
                    article.availableQuantity = orderLine.availableQuantity; 
                    article.isGift = orderLine.isGift;    
                    preErrQte.push(article); 
                  } 
                  });  
              }  
              setListErrQte([preErrQte]);
              setOpenErrQte(true);
            } 
          }); 
       }
      }else{
        console.log('true');
        setOpen(true);
      } 
    } 
         
  };
  const changeOrder = () => {
    const source = CancelToken.source();
    if (performingPut) {
      return;
    }
      const body = {};  
      body.orderId = (orderId)? orderId : 0;
      body.customerId = customer.customerId; 
      body.language = lng; 
      body.orderStatusId = orderStatus.ENREGISTRE;
      body.paymentModeId = 100;
      body.deliveryModeId = (deliveryMode == "To Go") ? 1 : 2;
      body.orderLines = orderLines?.map((element) => {
        const orderLine = {};  
        orderLine.articleId = element?.articleId;
        orderLine.quantity = element?.quantity;
        orderLine.quantityStep = element?.quantityStep;
        orderLine.minimumToOrder = element?.minimumToOrder;
        orderLine.isGift = element?.isGift;
        return orderLine;
      });  
      callPutOrder({ body, source }).then((response) => {
        if (response?.status == 200){   
          setOrderId(response.data.data.orderId);
          setSousTotalHt(response.data.data.totalDiscountedAmountHT + response.data.data.totalQdt);
          setCoutLogistique(response.data.data.totalRebates);
          setTva(response.data.data.totalTva);
          setTimbre(response.data.data.totalTimbre);
          setTotalPanier(response.data.data.totalAmountTTC);
          let preOrderLines = []; 
          let preIdArticlesInPanier = [];
          let preIdArticlesInPanierQte = [];
          if(response.data.data.orderLines){ 
            response.data.data.orderLines.map((orderLine) => { 
              let article = {};
              let minToOrder = ( orderLine.article.minimumToOrder > 0) ? orderLine.article.minimumToOrder : 1 ;
              let qteStep = ( orderLine.article.quantityStep > 0) ? orderLine.article.quantityStep : 1 ;
              article.articleId = orderLine.articleId;
              article.code = orderLine.article.code;
              article.imageListUrl = orderLine.article.imageListUrl;
              article.commercialDesignation = orderLine.article.commercialDesignation;
              article.commercialDesignationAr = orderLine.article.commercialDesignationAr;
              article.unitCode = orderLine.unitCode;
              article.caseItems = orderLine.article.caseItems;
              article.orderLineId = orderLine.orderLineId;
              article.quantity = orderLine.quantity;
              article.quantityStep = qteStep;
              article.minimumToOrder = minToOrder;
              article.unitText = orderLine.article.unitText;
              article.caseItems = orderLine.article.caseItems;
              article.promotionPriceTTC = orderLine.article.promotionPriceTTC;
              article.unitPriceHT = orderLine.article.unitPriceHT; 
              article.taxe = orderLine.article?.taxe?.value;
              article.inPromo = orderLine.discountAmountTtc > 0 ? true : false;   
              article.promotionPercent = orderLine.percentLpr;
              article.totalAmountTTC = orderLine.totalAmountTTC; 
              article.discountedSalesUnitPriceTTC = orderLine.discountedSalesUnitPriceTTC;
              article.salesUnitPriceTTC = orderLine.salesUnitPriceTTC;
              article.stockQuantity = orderLine.article.stockQuantity;
              article.discountedUnitPriceTTC = orderLine.discountedUnitPriceTTC;
              article.paliers = orderLine.article.articlePromotionInfo?.paliers;
              article.isGift = orderLine.isGift;   
              if(!orderLine.isGift){
                let objQte = {
                  id:orderLine.articleId,
                  qte:orderLine.quantity
                };
                preIdArticlesInPanierQte.push(objQte); 
                preIdArticlesInPanier.push(orderLine.articleId);
              } 
              preOrderLines.push(article); 
              }); 
              setOrderLines(preOrderLines);
              setIdArticlesInPanier(preIdArticlesInPanier);
              setIdArticlesInPanierQte(preIdArticlesInPanierQte);
              setListErrQte();
               setOpenErrQte(false);
          }  
        }
        if (response?.status == 202){  
          let preErrQte = [];
          if(response?.data?.data?.orderLines){ 
            response?.data?.data?.orderLines.map((orderLine) => {  
              if(orderLine.availableQuantity < orderLine.quantity){
                let article = {};
                let minToOrder = ( orderLine.article.minimumToOrder > 0) ? orderLine.article.minimumToOrder : 1 ;
                let qteStep = ( orderLine.article.quantityStep > 0) ? orderLine.article.quantityStep : 1 ;
                article.articleId = orderLine.articleId;   
                article.quantity = orderLine.quantity; 
                article.quantityStep = qteStep;
                article.minimumToOrder = minToOrder;
                article.availableQuantity = orderLine.availableQuantity; 
                article.isGift = orderLine.isGift;    
                preErrQte.push(article); 
              } 
              });  
          }  
          setListErrQte(preErrQte);
          setOpenErrQte(true);
        }  
      }); 
  };
  const onClose = () => { 
    setOpen(false); 
    setIdSlot(0); 
    setSlotReceipt(null); 
    setDisableButtons(false);
         
  };
  const changeQte = (orderLine,type,value = 0) => {    
    let myOrderLines = [];
    let myIdsArticlesInPanierQte = []; 
    let myIdArticlesInPanierQte ;
    let preIdArticlesInPanierQte = idArticlesInPanierQte;
    let preOrderLines = orderLines;
    let minToOrder = ( orderLine.minimumToOrder > 0) ? orderLine.minimumToOrder : 1 ;
      let qteStep = ( orderLine.quantityStep > 0) ? orderLine.quantityStep : 1 ;
    myIdsArticlesInPanierQte =preIdArticlesInPanierQte.filter(function( obj ) {
      if(obj.id !== orderLine?.articleId){
        return obj;
      }else{
        myIdArticlesInPanierQte = obj;
      }
      return obj.id !== orderLine?.articleId;
    });
    if(type == "plus"){  
      if(orderLine?.stockQuantity >= orderLine.quantity){ 
        if(orderLine.quantity < minToOrder){
          myIdArticlesInPanierQte.qte = minToOrder;
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{
              obj.quantity = minToOrder;
              return obj;
            } 
          });
          setRender(render + 1);
        }else if(orderLine.quantity == minToOrder){
          myIdArticlesInPanierQte.qte += qteStep;
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{
              obj.quantity += qteStep;
              return obj;
            } 
          });
          setRender(render + 1);
        }else{
          let rest = (orderLine.quantity - minToOrder) % qteStep ;
          myIdArticlesInPanierQte.qte += (qteStep - rest);
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{
              obj.quantity += (qteStep - rest);
              return obj;
            } 
          });
          setRender(render + 1);
        }  
      }else{  
        myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte + 0;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines =preOrderLines.filter(function( obj ) {
          if(obj.articleId !== orderLine?.articleId){
            return obj;
          }else{
            obj.quantity = obj.quantity+0;
            return obj;
          } 
        });
        showMsg("qte");
      }  
    }if(type == "minus"){ 
      if(orderLine.quantity > minToOrder){
        if(orderLine.quantity >= (minToOrder + qteStep)){
          let rest = (orderLine.quantity - minToOrder) % qteStep ;
          if(rest == 0){
            myIdArticlesInPanierQte.qte -= qteStep;
            myOrderLines =preOrderLines.filter(function( obj ) {
              if(obj.articleId !== orderLine?.articleId){
                return obj;
              }else{ 
                  obj.quantity -= qteStep;
                  return obj;  
              } 
            });
            myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);   
            setRender(render + 1);
          }else{ 
            myIdArticlesInPanierQte.qte -= rest;
            myOrderLines =preOrderLines.filter(function( obj ) {
              if(obj.articleId !== orderLine?.articleId){
                return obj;
              }else{ 
                  obj.quantity -= rest;
                  return obj;  
              } 
            });
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);   
          setRender(render + 1);
          }
        }else{ 
          myIdArticlesInPanierQte.qte = minToOrder;
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{ 
                obj.quantity = minToOrder;
                return obj;  
            } 
          });
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);   
          setRender(render + 1);
        } 
      }else{
        myIdArticlesInPanierQte.qte = 0;
        const array = idArticlesInPanier;  
        const index = array.indexOf(orderLine?.articleId);
        if (index > -1) {
          array.splice(index, 1);
        }
        myOrderLines =preOrderLines.filter(function( obj ) {
          if(obj.articleId !== orderLine?.articleId){
            return obj;
          }
        });
        setIdArticlesInPanier(array); 
        showMsg("remove");
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);   
        setRender(render + 1);
      }  
    }
    if(type == "numPadQte"){
      if(value <= 0){
        myIdArticlesInPanierQte.qte = 0;
        myOrderLines =preOrderLines.filter(function( obj ) {
          if(obj.articleId !== orderLine?.articleId){
            return obj;
          }else{
            const array = idArticlesInPanier;  
              const index = array.indexOf(orderLine?.articleId);
              if (index > -1) {
                array.splice(index, 1);
              }
              setIdArticlesInPanier(array); 
              showMsg("remove");
            
          } 
        });
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);   
        setRender(render + 1); 
      }
      else if(orderLine?.stockQuantity > value){ 
        if(value < minToOrder){
          myIdArticlesInPanierQte.qte = minToOrder;
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{
              obj.quantity = minToOrder;
              return obj;
            } 
          }); 
          setQteToOrder(minToOrder);
          showMsg("minToOrder");
          setRender(render + 1); 
        }else{
          let rest = (value - minToOrder) % qteStep ;
          
          
          myIdArticlesInPanierQte.qte = (value - rest);
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
          myOrderLines =preOrderLines.filter(function( obj ) {
            if(obj.articleId !== orderLine?.articleId){
              return obj;
            }else{
              obj.quantity = (value - rest);
              return obj;
            } 
          });  
          setRender(render + 1);  
        }  
      }else{  
        myIdArticlesInPanierQte.qte = orderLine?.stockQuantity;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines =preOrderLines.filter(function( obj ) {
          if(obj.articleId !== orderLine?.articleId){
            return obj;
          }else{
            obj.quantity = orderLine?.stockQuantity;
            return obj;
          } 
        });
        showMsg("qte");
        setRender(render + 1);
      }  
  }
    setOrderLines(myOrderLines); 
    setIdArticlesInPanierQte(myIdsArticlesInPanierQte);
  }; 
  const deleteFromPanier = () => { 
    if(articleIdToDelete > 0){
      let id = articleIdToDelete ;
      let myOrderLines = [];
      let myIdsArticlesInPanierQte = []; 
      let preIdArticlesInPanierQte = idArticlesInPanierQte;
      let preOrderLines = orderLines;
      myOrderLines =preOrderLines.filter(function( obj ) {
        if(obj.articleId !== id){
          return obj;
        } 
        return obj.articleId !== id;
      });
      myIdsArticlesInPanierQte =preIdArticlesInPanierQte.filter(function( obj ) {
        if(obj.id !== id){
          return obj;
        } 
        return obj.id !== id;
      });
      const array = idArticlesInPanier;  
      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
      } 
      setRender(render + 1);
      setIdArticlesInPanier(array);  
      setOrderLines(myOrderLines); 
      setIdArticlesInPanierQte(myIdsArticlesInPanierQte);
      showMsg("remove");
      setArticleIdToDelete(0);
      setOpenDeleteFromPanier(false);
    } 
    
  };   
  useEffect(() => {   
    if(orderLines){ 
      let preIdArticlesInPanierQte = []; 
      let preIdArticlesInPanier = []; 
      orderLines.map((orderLine) => {
        let objQte = {
          id:orderLine?.articleId,
          qte:orderLine?.quantity,
          price:orderLine?.unitPromotionPriceTTC
        }; 
        preIdArticlesInPanierQte.push(objQte);
        preIdArticlesInPanier.push(orderLine.articleId);
        }); 
        setIdArticlesInPanierQte(preIdArticlesInPanierQte);
        setIdArticlesInPanier(preIdArticlesInPanier);
    }  
  }, [customer,deliveryMode,orderLines]);   
  const changeQteNumPad = (article,qte) => {   
    setNumPadArticle(article);  
    setQteNumPadQte(qte);  
    setRenderNumPad(false);  
    setNumPadQte(true);
  };
  const onCloseNumPad = () => {  
    setQteNumPad();
    setNumPadQte(false);
  };
  const closeOpenDeleteFromPanier = () => {  
    setOpenDeleteFromPanier(false);
    setArticleToDelete(null);
    setArticleIdToDelete(0);
  };
  const closeOpenErrQte = () => {  
    setOpenErrQte(false); 
  };
  return (
    <>
    <StoreContainer>
    <>
    <StockDialog  
          open={openErrQte}
          onClose={() => closeOpenErrQte()} 
        > 
        </StockDialog>
    <ConfirmationDialog 
          textLeft={trans('supprimerArticle')}
          textRight={trans('panier.annuler')}
          open={openDeleteFromPanier}
          onClose={() => closeOpenDeleteFromPanier()}
          onSuccess={() => deleteFromPanier()}
        >
          {trans('panier.deleteProduct', { designation: articleIdDesignation })}
        </ConfirmationDialog>
    <NumPadQte  
          setQteNumPad={setQteNumPad}
          qteNumPad={qteNumPad}
          renderNumPad={renderNumPad}
          setRenderNumPad={setRenderNumPad}
          qte={qteNumPadQte}
          open={numPadQte}
          numPadArticle={numPadArticle} 
          onClose={() => onCloseNumPad()} 
          changeQte={(article,type,value) => changeQte(article,type,value)} 
        > 
        </NumPadQte>
    <SlotDialog  
          open={open} 
          setIdSlot={setIdSlot}
          idSlot={idSlot}
          channelId={customer.channelId}
          setDesiredDeliveryDate={setDesiredDeliveryDate}
          desiredDeliveryDate={desiredDeliveryDate}
          disableButtons={disableButtons}
          onClose={() => onClose()}
          onSuccess={() => confirmOrder()}
        > 
        </SlotDialog>
    <div className={lng == "ar" ? "page_panier page_panier_ar" :  "page_panier"}>
      <div className={(removeMsg || qteMsg || minToOrder)? 'dialog_msg dialog_msg_bloc' : 'dialog_msg'}> 
            <div className={(removeMsg)? "msg_bloc info_msg" : "info_msg"}>
              <img src="images/msg_delete.png" alt='' />
              <span>{trans('popupMsg.deleteArticle')}</span>
            </div>
            <div className={(qteMsg)? "msg_qte msg_bloc" : "msg_qte"}>
              <img src="images/i_red.png" alt='' />
              <span>{trans('popupMsg.qte')}</span>
            </div> 
            <div className={(minToOrder)? "msg_qte msg_bloc msg_min" : "msg_qte"}>
              <img src="images/i_red.png" alt='' />
              <span>{trans('popupMsg.minOrder', { qte: qteToOrder })}</span>
            </div>
          </div> 
      <div className='panier_top'>
        <div className='ctn_left'>
          <img src={deliveryMode == "To Go" ? "https://i.ibb.co/Qm1Lcqx/ToGo.png" : "https://i.ibb.co/2cG2mKJ/delivery.png"} alt=''/>
        </div>
        <div className='ctn_center'>
          <p><span>{trans('panier.monPanier')} </span><span>({orderLines?.length ? orderLines.map(orderLine => orderLine.quantity).reduce((a, b) => a + b) : 0})</span></p>
          <p className='ref'><span>{orderLines?.length}</span><span>{orderLines?.length > 1 ? trans('panier.references') : trans('panier.reference')}</span></p>
          <p>{deliveryMode == "To Go" ? trans('panier.aPrendre') : trans('panier.aLivrer')}</p>
        </div>
        <div className='ctn_right'> 
              { customer?.firstName &&
                <div>
                  <p>{`${customer?.firstName} ${customer?.lastName}`}</p> 
                  <p>{customerPhone}</p>
                </div>
              }
              { !customer?.firstName &&
                <div> 
                  <p>{customerPhone}</p>
                </div>
              }
        </div>
      </div>
      <div className='ctn_orderlines'>
      {orderLines?.map((item) => (
        <Fade  bottom   key={item?.articleId}>
        <div className='orderline'> 
        {(item?.promotionPercent > 0 && !item?.isGift) &&
        <div className='promo'>
          <span>{parseInt(item?.promotionPercent)}%</span>
        </div>
        }
        <div className='ctn_img'>
          <img src={item?.imageListUrl[0]} alt=''/>
        </div>
        <div className='ctn_inf'>
          <div className='ctn_desc'>
            <h5>{lng == "ar" ? item?.commercialDesignationAr : item?.commercialDesignation}</h5>
          </div>  
          {item?.isGift &&
              <div className='ctn_info gratuit'> 
                <p>{item?.quantity < 2 ? trans('free.one') : trans('free.many', { qte: item?.quantity })}</p>
              </div>
            }
            {(!item?.isGift && item?.unitText) &&
            <div className='ctn_info'>
              <p>{(item?.unitText == "Pièce") ? trans(item?.unitText) :`${trans(item?.unitText)} : ${item?.caseItems} ${trans('Pièces')} × ${item?.discountedUnitPriceTTC} ${trans('dh')} `}</p>
            </div>
            }
            {!item?.isGift &&
            <div className='ctn_price'> 
              {item?.inPromo ? ( 
                  <> 
                  <span>{item?.discountedSalesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                  <span>{item?.salesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                </> 
                ) : ( 
                <>
                <span>{item?.salesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                <span></span>
                
                </>
                )} 
            </div>
            }
            {item?.isGift &&
              <div className='ctn_gratuite'> 
                <p>{trans('free.free')}</p>
              </div>
            }
        </div>
        {!item?.isGift &&
        <div className='qte_horizontal'>
          <div className='ctn'>
            <div className='plus' onClick={() => changeQte(item,'plus')}>
              <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
            </div>
            {idArticlesInPanier.includes(item?.articleId) &&
              <input type="text" onClick={() => changeQteNumPad(item,idArticlesInPanierQte?.find(obj => { return obj.id === item?.articleId }).qte)} onChange={() => 1} value={idArticlesInPanierQte?.find(obj => { return obj.id === item?.articleId }).qte} />
            }
            <div className='minus' onClick={() => changeQte(item,'minus')}>
              <img src='https://i.ibb.co/9p0Rtcp/remove.png' alt=''/>
            </div>
          </div>
        </div>
        }
        {!item?.isGift &&
        <div className='total_price'>
        {idArticlesInPanier.includes(item?.articleId) &&
          <span>{item?.totalAmountTTC?.toFixed(2)} {trans('dh')}</span>
        }
        </div>
        }
        {!item?.isGift &&
        <div className='btn_delete' onClick={() => setArticleToDelete(item)}>
          <img src='https://i.ibb.co/ccMpf5N/close-1.png' alt=''/>
        </div>
        }
        <div className='errQte'>
        <span>{(listErrQte?.find(obj => { return obj.articleId === item?.articleId })?.availableQuantity > 0 && !item?.isGift) ? trans('orderLineErrQte', { qte: listErrQte?.find(obj => { return obj.articleId === item?.articleId })?.availableQuantity })   : '' }</span>  
        </div>
      </div> 
      </Fade>
        ))}
         
      </div>  
      <div  className="ctn_panier">
      {totalPanier > 0 && totalPanier < minimumOrder && deliveryMode !== "To Go" &&
        <p className='info_seuil'><img src='https://upload.wikimedia.org/wikipedia/commons/4/43/Minimalist_info_Icon.png'/>{trans('panier.panierMsg', { minimum: minimumOrder })}</p>
      }
          <div className='panier_info sub_total'>
            <span>{trans('panier.totalHt')}</span>
            <span>{sousTotalHt} {trans('dh')}</span>
          </div>
          <div className='panier_info sub_total'>
            <span>{trans('panier.coutLogistique')}</span>
            <span>{coutLogistique} {trans('dh')}</span>
          </div>
          <div className='panier_info sub_total'>
            <span>{trans('panier.remiseLogistique')}</span>
            <span>-{coutLogistique} {trans('dh')}</span>
          </div>
          <div className='panier_info taxe'>
            <span>{trans('panier.tva')}</span>
            <span>{tva} {trans('dh')}</span>
          </div> 
          <div className='total_price'>
            <span>{trans('panier.total')}</span>
            <span>{totalPanier?.toFixed(2)} {trans('dh')}</span>
          </div>
          <div className='panier_info taxe'>
            <span>{trans('droitTimbre')}</span>
            <span>{timbre} {trans('dh')}</span>
          </div>
          <div className='ctn_btn'> 
            <div onClick={() => onClickCatalogue()} className='btn_order more_order'>
              <span>{trans('panier.continuerAchats')}</span>
            </div>
            <div onClick={() => confirmOrder()} className={(totalPanier < store.mininumToOrder && deliveryMode !== "To Go") ? "btn_order add_order btn_order_disabled" : "btn_order add_order"}> 
              <span>{trans('panier.validerCommande')}</span>
            </div>
          </div>
        </div>
        <div style={{display: 'none'}}> 
      </div>
    </div>
    </>
    </StoreContainer>
      
    </>
  );
};
export default Panier;
