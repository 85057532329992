import axios from 'axios';
import storage from 'helpers/storage';
import refreshToken from './refreshTokenInterceptor';

const baseURL = process.env.REACT_APP_API_URL; 
export const client = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApplicationId': 'chari',
    'platform': 'web',
    'version': '1.0.0',  
    'SalesChannel': 4,  
  },
});  
export const REFRESH_TOKEN_PATH = 'api/store/users/login/refresh';  
export const SIGN_IN_PATH = 'api/store/users/login';
export const CUSTOMER_SIGN_IN_PATH = 'api/store/customers/login';
export const CATALOGUEAPI = 'api/store/catalog';  
export const ORDERS_PATH = 'api/store/orders';  
export const CHANNELS = 'api/store/customers/channels';  
export const SLOTS = 'api/store/orders/slots';  
export const PROMOTIONS = 'api/store/articles/promotions';   
export const BANNERS_PATH = 'api/banners';
export const WAREHOUSES = 'api/store/users/warehouse';  
export const errorMessage = 'Problème serveur. Veuillez réessayer ultérieurement';
export const successMessage = 'Action accomplie avec succès';

const withoutBearer = [SIGN_IN_PATH];
const withoutRefreshTokenInterceptor = [SIGN_IN_PATH];

client.interceptors.request.use(
  (config) => {
    const token = storage.getToken();
    const borne = storage.getBorne();
    if (token !== null && !withoutBearer.includes(config.url)) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.deviceid = `${borne}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

client.interceptors.response.use(
  (response) => response,
  (err) => {
    if (!withoutRefreshTokenInterceptor.includes(err?.config?.url)) {
      return refreshToken(err);
    }
    return Promise.reject(err);
  }
);

export const CancelToken = axios.CancelToken;

export default client;
