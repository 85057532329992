import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';
import { Typography, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

const PageTitle = ({ children, onBackClick, className }) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={clsx(classes.root, className)}>
      {onBackClick && (
        <IconButton onClick={onBackClick} className={classes.backButton}>
          <ArrowBack />
        </IconButton>
      )}
      {children}
    </Typography>
  );
};

PageTitle.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  onBackClick: PropTypes.func,
};

export default PageTitle;
