import React, {useEffect,useContext,useState} from 'react';
import { useTranslation } from 'react-i18next'; 
import { useHistory } from 'react-router-dom';
import StoreContainer from 'pages/StoreContainer';  
import { OrderContext } from 'pages/OrderContext';
import moment from 'moment'; 
import Fade from 'react-reveal/Fade';
import Tada from 'react-reveal/Tada';
import Flip from 'react-reveal/Flip';
import storage from 'helpers/storage';
const ConfirmedOrder = () => { 
  const store = storage.getStore();
  const {customer,deliveryMode,setOrderLines,orderLines, orderId,setOrderId,footerOrder,counter,orderConfirmedAt,customerPhone,slotReceipt} = useContext(OrderContext);
  const [orderLinesTableBody, setOrderLinesTableBody] = useState([]);  
  const [orderLinesTableBodyAr, setOrderLinesTableBodyAr] = useState([]);  
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const history = useHistory(); 
  const [lng, setLng] = useState();   
  const [redirectNow, setRedirectNow] = useState(false); 
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const redirectToStart = async () => {  
    await sleep(10000);
    setRedirectNow(true);
  };
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);   
  useEffect(() => {  
    if(redirectNow)
    history.push('/startPage');
  }, [redirectNow]);
  const getUte = (item) => { 
    if(item == "UNITE")
      return 'Pce';
    if(item == "CAISSE")
      return 'Cse'; 
  }; 
  useEffect(() => {   
    if(orderLines && lng){ 
      console.log('oui');
      console.log('lng',lng);
      if(lng == "ar"){ 
        let preOrderLinesTableBodyAr = orderLines?.map((element) => {
          let orderLineTableBodyAr;  
          orderLineTableBodyAr = [ 
                    {
                      type: 'text',
                      value:  element?.totalAmountTTC.toFixed(2),
                      style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                      },
                      {
                        type: 'text',
                        value: trans(getUte(element?.unitCode)),
                        style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                        },{
                          type: 'text',
                          value: element?.quantity,
                          style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                          },{
                              type: 'text',
                              value: (element?.inPromo) ? element?.discountedSalesUnitPriceTTC?.toFixed(2) : element?.salesUnitPriceTTC?.toFixed(2),
                              style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                              },
                              {
                                type: 'text',
                                value: `${element?.code} <br/> ${element?.commercialDesignationAr}`,
                                style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'right', fontSize: "11px",color:'black',fontFamily:"math"}
                                }
          ];  
            
          return orderLineTableBodyAr;
        }); 
      
      setOrderLinesTableBodyAr(preOrderLinesTableBodyAr);
      }else{
        let preOrderLinesTableBody; 
        preOrderLinesTableBody = orderLines?.map((element) => {
          let orderLineTableBody;  
          orderLineTableBody = [
            {
              type: 'text',
              value: `${element?.code} <br/> ${element?.commercialDesignation}`,
              style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'left', fontSize: "11px",color:'black',fontFamily:"math"}
              },
              {
                type: 'text',
                value: (element?.inPromo) ? element?.discountedSalesUnitPriceTTC?.toFixed(2) : element?.salesUnitPriceTTC?.toFixed(2),
                style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                },
                {
                  type: 'text',
                  value: element?.quantity,
                  style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                  },
                  {
                    type: 'text',
                    value: trans(getUte(element?.unitCode)),
                    style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                    },
                  {
                    type: 'text',
                    value:  element?.totalAmountTTC.toFixed(2),
                    style: {border: "0",margin: "2px 0 0 0",padding: "2px 0 0 0",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                    }
        ]; 
            
          return orderLineTableBody;
        }); 
      
      setOrderLinesTableBody(preOrderLinesTableBody);
      }
      
      
    } 
  }, [orderLines , lng]); 
  useEffect(() => {  
    if(orderLinesTableBody.length>0 || orderLinesTableBodyAr.length>0){
    /*====================impression test=============================*/
    const date = new Date(orderConfirmedAt);
    const now = moment(date).format('DD/MM/YYYY HH:mm');
    const options = {
      preview: false,
      width: "300px", 
      margin: "0 0 0 0", 
      copies: 1, 
      timeOutPerLine: 400, 
      silent: true,  
      pageSize: '80mm'
    };
    const dataAr = [
      {
        type: 'text',                          
        value: counter,
        style: {margin: "0px",padding: "0px",fontWeight: "700",textAlign: 'center', fontSize: "35px",fontFamily:"math"}
      },
      {
        type: 'table', 
        style: {'border': '0px solid #fff',margin: "0px",padding: "0px",fontWeight: "600",fontFamily:"math"}, 
        tableHeader: [ 
              {
                type: 'text',
                value: '',
                style: {width: "100px",textAlign: 'left', fontSize: "11px",color:'black'}
                },
                {
                  type: 'text',
                  value: '',
                  style: {width: "200px",textAlign: 'right', fontSize: "11px",color:'black'}
                  }
          ], 
        tableBody: [ 
            [{
                  type: 'text',
                  value: deliveryMode == 'To Go' ? trans('retrait') : trans('livraison'),
                  style: {margin: "0px",padding: "0px",width: "100px",textAlign: 'left', fontSize: "11px",color:'black'}
                  },
                  {
                    type: 'text',
                    value: trans('cmdN', { order: orderId}),
                    style: {margin: "0px",padding: "0px",width: "200px",textAlign: 'right', fontSize: "11px",color:'black'}
                    }], [
                      {
                        type: 'text',
                        value: deliveryMode == 'To Go' ? '' : slotReceipt,
                        style: {margin: "0px",padding: "0px",width: "200px",textAlign: 'left', fontSize: "11px",color:'black'}
                        },
                     {
                    type: 'text',
                    value: now,
                    style: {margin: "0px",padding: "0px",width: "100px",textAlign: 'right', fontSize: "11px",color:'black'}
                    }] 
        ],
        tableFooter: ['text1', 'text2'],
        tableHeaderStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
        tableBodyStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px"}, 
        tableFooterStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px",backgroundColor: '#000', color: 'white',display:'none'}, 
      },
      {
        type: 'text',                         
        value: '---------------------------------------------------------------------------',
        style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
      },
      {
        type: 'text',                          
        value: trans('chari'),
        style: {fontWeight: "600",textAlign: 'center', fontSize: "11px",margin: "0px",padding: "0px",fontFamily:"math"}
      },
      {
        type: 'text',                          
        value: `${store.addressAr}`,
        style: {fontWeight: "600",textAlign: 'center', fontSize: "11px",margin: "0px",padding: "0px",fontFamily:"math"}
      },
      {
        type: 'text',
        value: `${store.descriptionAr}  -   ${store.phone}`,
        style: {margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
        }, 
      {
        type: 'text',                         
        value: '---------------------------------------------------------------------------',
        style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
      },
      {
        type: 'text',
        value: `${customer.code} - ${customer?.firstName} ${customer?.lastName} - ${customerPhone}`,
        style: {marginTop: "5px 0 0 0",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
        }, 
        {
          type: 'text',
          value: `Lat : ${parseFloat(customer.latitude).toFixed(6)} - Lng : ${parseFloat(customer.longitude).toFixed(6)}`,
          style: {margin: "5px 0 0 0",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
          }, 
      {
        type: 'text',                         
        value: '---------------------------------------------------------------------------',
        style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
      }, 
      {
          type: 'table',
          // style the table
          style: {border: "0",margin: "5px 0 11px 0",padding: "0px",fontWeight: "600",fontFamily:"math",color:'black', fontSize: "11px"},
          // list of the columns to be rendered in the table header 
          tableHeader: [
            {
              type: 'text',
              value: trans('receipt.total'),
              style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
              } ,
              {
                type: 'text',
                value: trans('receipt.ute'),
                style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                },
                {
                  type: 'text',
                  value: trans('receipt.qte'),
                  style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                  },
                  {
                    type: 'text',
                    value: trans('receipt.puNet'),
                    style: {border: "0",margin: "0px",padding: "0px",width:"40px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                    } ,
            {
              type: 'text',
              value: trans('receipt.article', { nbarticle: orderLines.length}),
              style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'right', fontSize: "11px",color:'black',fontFamily:"math"}
              },
              
                  
          ],
          tableBody: orderLinesTableBodyAr,
          // list of columns to be rendered in the table footer
          tableFooter: ['text1', 'text2'],
          // custom style for the table header
          tableHeaderStyle: {border: "0",margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
          // custom style for the table body
          tableBodyStyle: {border: "0",margin: "0px",padding: "0px"},
          // custom style for the table footer
          tableFooterStyle: {border: "0",backgroundColor: '#000', color: 'white',display:'none'}, 
          }
        ,{
          type: 'table', 
          style: {border: "none",margin: "0px",padding: "0px",fontWeight: "600",fontFamily:"math",cellspacing:"0",cellpadding:"0"}, 
          tableHeader: [
            {
              type: 'text',
              value: '',
              style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black'}
              }, 
                {
                  type: 'text',
                  value: '',
                  style: {width: "160px",textAlign: 'center', fontSize: "11px",color:'black'}
                  }, 
                  {
                    type: 'text',
                    value: '',
                    style: {width: "100px",textAlign: 'center', fontSize: "11px",color:'black'}
                    }
            ], 
          tableBody: [
               
                        [
                          {
                            type: 'text',
                            value: footerOrder.totalDiscountedAmountHT.toFixed(2),
                            style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                            },
                          {
                            type: 'text',
                            value: trans('panier.totalNetHt'),
                            style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                            },
                            {
                          type: 'text',
                          value: '',
                          style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                          },   ],
                                  [
                                    {
                                      type: 'text',
                                      value: footerOrder.totalRebates.toFixed(2),
                                      style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                      },
                                    {
                                      type: 'text',
                                      value: trans('panier.coutLogistique'),
                                      style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                      },
                                      {
                                    type: 'text',
                                    value: '',
                                    style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                    } 
                                          ],
                                            [
                                              {
                                                type: 'text',
                                                value: '-'+footerOrder.totalRebates.toFixed(2),
                                                style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                },
                                              {
                                                type: 'text',
                                                value: trans('panier.remiseLogistique'),
                                                style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                },
                                                {
                                              type: 'text',
                                              value: '',
                                              style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                              }  
                                                    ],  
                                  [
                                    {
                                      type: 'text',
                                      value: footerOrder.totalTva.toFixed(2),
                                      style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                      },
                                    {
                                      type: 'text',
                                      value: trans('panier.totalTva'),
                                      style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                      },
                                      {
                                    type: 'text',
                                    value: '',
                                    style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                    } 
                                         ],
                                            [{
                                              type: 'text',
                                              value: footerOrder.totalTimbre.toFixed(2),
                                              style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                              }, 
                                              {
                                                type: 'text',
                                                value: trans('panier.timbre'),
                                                style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                },
                                                {
                                              type: 'text',
                                              value: '',
                                              style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                              } 
                                                    ],
                                                                [
                                                                  {
                                                                    type: 'text',
                                                                    value: footerOrder.totalAmountTTC.toFixed(2),
                                                                    style: {width: "100px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                    },
                                                                  {
                                                                    type: 'text',
                                                                    value: trans('panier.totalTtc'),
                                                                    style: {width: "160px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                    },
                                                                    {
                                                                  type: 'text',
                                                                  value: '',
                                                                  style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                  }  ]
          ],
          tableFooter: ['text1', 'text2'],
          tableHeaderStyle: {border: "none",margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
          tableBodyStyle: {border: "none",margin: "0px",padding: "0px"}, 
          tableFooterStyle: {border: "none",margin: "0px",padding: "0px",backgroundColor: '#000', color: 'white',display:'none'}, 
        },
        {
          type: 'text',                         
          value: '---------------------------------------------------------------------------',
          style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
        },
        {
          type: 'text',                          
          value: trans('receipt.thanks'),
          style: {fontWeight: "700",textAlign: 'center', fontSize: "17px",paddingBottom: "11px", margin: "2px 0",fontFamily:"math"}
        }
  ]; 
  const data = [
    {
      type: 'text',                          
      value: counter,
      style: {margin: "0px",padding: "0px",fontWeight: "700",textAlign: 'center', fontSize: "35px",fontFamily:"math"}
    },
    {
      type: 'table', 
      style: {'border': '0px solid #fff',margin: "0px",padding: "0px",fontWeight: "600",fontFamily:"math"}, 
      tableHeader: [
        {
          type: 'text',
          value: '',
          style: {width: "200px",textAlign: 'left', fontSize: "11px",color:'black'}
          }, 
            {
              type: 'text',
              value: '',
              style: {width: "100px",textAlign: 'right', fontSize: "11px",color:'black'}
              }
        ], 
      tableBody: [ 
          [ {
            type: 'text',
            value: trans('cmdN', { order: orderId}),
            style: {margin: "0px",padding: "0px",width: "200px",textAlign: 'left', fontSize: "11px",color:'black'}
            },
            {
                type: 'text',
                value: deliveryMode == 'To Go' ? trans('retrait') : trans('livraison'),
                style: {margin: "0px",padding: "0px",width: "100px",textAlign: 'right', fontSize: "11px",color:'black'}
                }
                ], [{
                  type: 'text',
                  value: now,
                  style: {margin: "0px",padding: "0px",width: "100px",textAlign: 'left', fontSize: "11px",color:'black'}
                  }, 
                    {
                      type: 'text',
                      value: deliveryMode == 'To Go' ? '' : slotReceipt,
                      style: {margin: "0px",padding: "0px",width: "200px",textAlign: 'right', fontSize: "11px",color:'black'}
                      }] 
      ],
      tableFooter: ['text1', 'text2'],
      tableHeaderStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
      tableBodyStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px"}, 
      tableFooterStyle: {'horizontalLine': 'false','border': '0.5px solid #fff',margin: "0px",padding: "0px",backgroundColor: '#000', color: 'white',display:'none'}, 
    },
    {
      type: 'text',                         
      value: '---------------------------------------------------------------------------',
      style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
    },
    {
      type: 'text',                          
      value: trans('chari'),
      style: {fontWeight: "600",textAlign: 'center', fontSize: "11px",margin: "0px",padding: "0px",fontFamily:"math"}
    },
    {
      type: 'text',                          
      value: `${store.address}`,
      style: {fontWeight: "600",textAlign: 'center', fontSize: "11px",margin: "0px",padding: "0px",fontFamily:"math"}
    },
    {
      type: 'text',
      value: `${store.description}  -   ${store.phone}`,
      style: {margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
      }, 
    {
      type: 'text',                         
      value: '---------------------------------------------------------------------------',
      style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
    },
    {
      type: 'text',
      value: `${customer.code} - ${customer?.firstName} ${customer?.lastName} - ${customerPhone}`,
      style: {marginTop: "5px 0 0 0",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
      }, 
      {
        type: 'text',
        value: `Lat : ${parseFloat(customer.latitude).toFixed(6)} - Lng : ${parseFloat(customer.longitude).toFixed(6)}`,
        style: {margin: "5px 0 0 0",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
        }, 
    {
      type: 'text',                         
      value: '---------------------------------------------------------------------------',
      style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
    }, 
    {
        type: 'table',
        // style the table
        style: {border: "0",margin: "5px 0 11px 0",padding: "0px",fontWeight: "600",fontFamily:"math",color:'black', fontSize: "11px"},
        // list of the columns to be rendered in the table header 
        tableHeader: [
          {
            type: 'text',
            value: trans('receipt.article', { nbarticle: orderLines.length}),
            style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'left', fontSize: "11px",color:'black',fontFamily:"math"}
            },
            {
              type: 'text',
              value: trans('receipt.puNet'),
              style: {border: "0",margin: "0px",padding: "0px",width:"40px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
              },
              {
                type: 'text',
                value: trans('receipt.qte'),
                style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                },
                {
                  type: 'text',
                  value: trans('receipt.ute'),
                  style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                  } ,
                {
                  type: 'text',
                  value: trans('receipt.total'),
                  style: {border: "0",margin: "0px",padding: "0px",fontWeight: "600",textAlign: 'center', fontSize: "11px",color:'black',fontFamily:"math"}
                  } 
        ],
        tableBody: orderLinesTableBody,
        // list of columns to be rendered in the table footer
        tableFooter: ['text1', 'text2'],
        // custom style for the table header
        tableHeaderStyle: {border: "0",margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
        // custom style for the table body
        tableBodyStyle: {border: "0",margin: "0px",padding: "0px"},
        // custom style for the table footer
        tableFooterStyle: {border: "0",backgroundColor: '#000', color: 'white',display:'none'}, 
        }
      ,{
        type: 'table', 
        style: {border: "none",margin: "0px",padding: "0px",fontWeight: "600",fontFamily:"math",cellspacing:"0",cellpadding:"0"}, 
        tableHeader: [
          {
            type: 'text',
            value: '',
            style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black'}
            }, 
              {
                type: 'text',
                value: '',
                style: {width: "160px",textAlign: 'center', fontSize: "11px",color:'black'}
                }, 
                {
                  type: 'text',
                  value: '',
                  style: {width: "100px",textAlign: 'center', fontSize: "11px",color:'black'}
                  }
          ], 
        tableBody: [
             
                      [{
                        type: 'text',
                        value: '',
                        style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                        },  
                         {
                              type: 'text',
                              value: trans('panier.totalNetHt'),
                              style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                              }, 
                              {
                                type: 'text',
                                value: footerOrder.totalDiscountedAmountHT.toFixed(2),
                                style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                }],
                                [{
                                  type: 'text',
                                  value: '',
                                  style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                  },  
                                      {
                                        type: 'text',
                                        value: trans('panier.coutLogistique'),
                                        style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                        }, 
                                        {
                                          type: 'text',
                                          value: footerOrder.totalRebates.toFixed(2),
                                          style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                          }],
                                          [{
                                            type: 'text',
                                            value: '',
                                            style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                            }, 
                                                {
                                                  type: 'text',
                                                  value: trans('panier.remiseLogistique'),
                                                  style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                  }, 
                                                  {
                                                    type: 'text',
                                                    value: '-'+footerOrder.totalRebates.toFixed(2),
                                                    style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                    }],  
                                [{
                                  type: 'text',
                                  value: '',
                                  style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                  }, 
                                      {
                                        type: 'text',
                                        value: trans('panier.totalTva'),
                                        style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                        }, 
                                        {
                                          type: 'text',
                                          value: footerOrder.totalTva.toFixed(2),
                                          style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                          }],
                                          [{
                                            type: 'text',
                                            value: '',
                                            style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                            },
                                                {
                                                  type: 'text',
                                                  value: trans('panier.timbre'),
                                                  style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                  }, 
                                                  {
                                                    type: 'text',
                                                    value: footerOrder.totalTimbre.toFixed(2),
                                                    style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                    }],
                                                              [{
                                                                type: 'text',
                                                                value: '',
                                                                style: {width: "40px",textAlign: 'left', fontSize: "11px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                }, 
                                                                    {
                                                                      type: 'text',
                                                                      value: trans('panier.totalTtc'),
                                                                      style: {width: "160px",textAlign: 'left', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                      }, 
                                                                      {
                                                                        type: 'text',
                                                                        value: footerOrder.totalAmountTTC.toFixed(2),
                                                                        style: {width: "100px",textAlign: 'right', fontSize: "12px",color:'black', margin: "0px", padding: "0px",fontFamily:"math"}
                                                                        }]
        ],
        tableFooter: ['text1', 'text2'],
        tableHeaderStyle: {border: "none",margin: "0px",padding: "0px", backgroundColor: '#000', color: 'white'},
        tableBodyStyle: {border: "none",margin: "0px",padding: "0px"}, 
        tableFooterStyle: {border: "none",margin: "0px",padding: "0px",backgroundColor: '#000', color: 'white',display:'none'}, 
      },
      {
        type: 'text',                         
        value: '---------------------------------------------------------------------------',
        style: {letterSpacing: "2px",fontWeight: "600",textAlign: 'center', fontSize: "12px", margin: "0px", padding: "0px",height:'11px',width:'300px',overflow: 'hidden'}
      },
      {
        type: 'text',                          
        value: trans('receipt.thanks'),
        style: {fontWeight: "700",textAlign: 'center', fontSize: "17px",paddingBottom: "11px", margin: "2px 0",fontFamily:"math"}
      }
]; 
    if(window.require){
      let { remote } = window.require("electron");
      const { PosPrinter } = remote.require("electron-pos-printer"); 
      if(lng == "ar"){
        PosPrinter.print(dataAr, options)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
      PosPrinter.print(dataAr, options)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      }); 
      }else{
        PosPrinter.print(data, options)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
      PosPrinter.print(data, options)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      }); 
      } 
      redirectToStart();
    }  
  } 
  
  }, [orderLinesTableBody,orderLinesTableBodyAr]); 
  const cancel = () => {
    history.push('/startPage'); 
  };
  const newOrder = () => {
    setOrderLines();
    setOrderId(null);
    history.push('/pre/order/deliveryMethods'); 
  };
  return (
    <>
    <StoreContainer>
      <div className={lng == "ar" ? "confirmed_order confirmed_order_ar" :  "confirmed_order"}>
      <Tada><h3>{trans('confirmOrder.merci')} { customer?.firstName ? `${customer?.firstName} ${customer?.lastName}` : ''}</h3></Tada>
      <Flip left>
      <div className='ctn_img'>
        <img src='https://i.ibb.co/dM9mqW1/Order-confirmed.png'/>
      </div>
      </Flip>
      <h4>{trans('confirmOrder.p1')}</h4> 
      <h6>{trans('confirmOrder.p3')}</h6>
      <div className='ctn_btn'>
      <Fade left>
        <div onClick={() => cancel()}>
              <span>{trans('confirmOrder.NonMerci')}</span>
            </div>
            </Fade>
            <Fade right>
            <div onClick={() => newOrder()}> 
              <span>{trans('confirmOrder.OuiBienSur')}</span>
            </div> 
            </Fade>
      </div>
      </div>
    </StoreContainer>
      
    </>
  );
};
export default ConfirmedOrder;
