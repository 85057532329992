import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core'; 
import Content from './Content';

const ConfirmationDialog = ({
  textLeft,
  textRight,
  open,
  onClose,
  onSuccess,
  children,
  disableButtons, 
}) => {
  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <Content
        textLeft={textLeft}
        textRight={textRight}
        onClose={onClose}
        onSuccess={onSuccess} 
        disableButtons={disableButtons}
      >
        {children}
      </Content>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool,
  children: PropTypes.any, 
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
};

export default ConfirmationDialog;
