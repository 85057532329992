import React, { useState , useEffect ,useContext } from 'react';
import { Button, Typography} from '@material-ui/core';  
import { useParams } from 'react-router-dom';      
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';
import useStyles from 'pages/styles';  
import { useHistory } from 'react-router-dom'; 
import NumPad from 'utils/components/NumPad';
import clsx from 'clsx';
import { isOtp } from 'helpers/validators';
import { OrderContext } from 'pages/OrderContext';
import { CancelToken, CUSTOMER_SIGN_IN_PATH } from 'myAxios';
import useAxios, { POST } from 'hooks/useAxios';
import ButtonBack from 'utils/components/buttonBack'; 
import Tada from 'react-reveal/Tada';
import Fade from 'react-reveal/Fade';
const SignInCustomerOtp = () => {
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode,channel,setSlotReceipt} = useContext(OrderContext); 
  useEffect(() => {  
    setOrderLines();
    setSlotReceipt(null);
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
  }, []); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n; 
  const { phone } = useParams();    
  const [otp, setOtp] = useState(null);   
  const classes = useStyles(); 
  const history = useHistory(); 
  const [errMsg, setErrMsg] = useState(false);  
  const [lng, setLng] = useState();  
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]); 
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const showMsg = async () => { 
    setErrMsg(true);
      await sleep(5000);
      setErrMsg(false); 
  };
  const { call } = useAxios(POST, CUSTOMER_SIGN_IN_PATH, {
    withProgressBar: true,
  });
  function handleChange(otp) {
    setOtp(otp);
  }
  //===========================
  const [enableOtp, setEnableOtp] = useState(false); 
  useEffect(() => { 
    setEnableOtp(isOtp(otp));  
  }, [otp]); 
  //===========================
  const onSubmit = (e) => {
    e.preventDefault();  
    const source = CancelToken.source(); 
    const body = {};  
    body['phoneNumber'] = phone; 
    body['code'] = otp; 
    body['skipSendCode'] = false;  
    body['customer'] = {
      "channelId": channel
    };  
    call({ body, source }).then((response) => {  
      if(response?.status === 200){
        let apiCustomer = response.data.data;
        let customer = {};
        customer.customerStatusId = apiCustomer.customerStatusId;
        customer.customerId = apiCustomer.customerId;
        customer.contactName = apiCustomer.contactName;
        customer.firstName = apiCustomer?.user?.firstName;
        customer.lastName = apiCustomer?.user?.lastName; 
        customer.code = apiCustomer.code; 
        customer.channelId = apiCustomer.channelId; 
        customer.latitude = 0; 
        customer.longitude = 0; 
        setCustomer(customer);
        history.push('/pre/order/deliveryMethods');
      }else{
        showMsg(); 
      } 
    });  
  }; 
  return (
    <div className={lng == "ar" ? "screen_background screen_background_otp screen_background_otp_ar" :  "screen_background screen_background_otp"}>
      {errMsg &&
        <div className='popup_msg'>
              <img src="/images/i_red.png" alt='' />
              <span>{trans('authenticationCustomer.error')}</span>
            </div>
            }
      <ButtonBack   
              link={"/startPage"} 
              icon={true}
            /> 
            <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
            <Tada>
        <Typography component="h1" variant="h5">
          {trans('authenticationCustomer.otp')} {phone}
        </Typography>
        </Tada>
        <div className={"ctn_login"}>   
          <div className={clsx(classes.form, 'login_phone')} noValidate>
            <div className="ctn_otp">
              <OtpInput
                value={otp}
                className="otp" 
                onChange={handleChange}
                numInputs={6}
                isInputNum={true}
                separator={<span>-</span>}
              /> 
            </div> 
            <div className='login_numpad'>  
            <NumPad
              value={otp}
              setValue={setOtp} 
              type='otp'
            /> 
            </div> 
            <Button 
              type="button" 
              disabled={!enableOtp}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onSubmit}
            >
              {trans('suivant')}
            </Button>
          </div> 
          </div>
      </div> 
  );
};

export default SignInCustomerOtp;
