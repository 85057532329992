import React, {useEffect,useState} from 'react';
import PropTypes from 'prop-types';  
import { useTranslation } from 'react-i18next'; 
import { Dialog,DialogContent } from '@material-ui/core';

const CguDialog = ({ 
  open,
  onClose, 
}) => {     
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const [lng, setLng] = useState();  
  useEffect(() => { 
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]); 
  return (
    <Dialog  className={lng == "ar" ? "popup_cgu popup_cgu_ar" :  "popup_cgu"} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogContent>
      <div className="cgu_ctn">  
        <div className='close_i'>
          <div onClick={() => onClose()} >
            <img src='https://i.ibb.co/ccMpf5N/close-1.png' alt=''/>
          </div>
        </div> 
        <div className="cgu">
          <p>{trans('cgu.p1')}</p>
          <p>{trans('cgu.p2')}</p>
          <p>{trans('cgu.p3')}</p>
          <p>{trans('cgu.p4')}</p>
          <p>{trans('cgu.p5')}</p>
          <p>{trans('cgu.p6')}</p>
          <p>{trans('cgu.p7')}</p>
          <p>{trans('cgu.p8')}</p>
          <p>{trans('cgu.p9')}</p>
          <p>{trans('cgu.p10')}</p>
        </div> 
      </div>
      </DialogContent> 
    </Dialog>
  );
}; 
CguDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired, 
};

export default CguDialog;
