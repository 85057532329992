import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';    
import { Button, Input } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumPad from '../NumPad';
const NumPadQte = ({ 
  changeQte,
  qteNumPad,
  qte,
 setQteNumPad,
  numPadArticle, 
  numPadArticleCatalog, 
  open,
  onClose, 
  renderNumPad, 
  setRenderNumPad, 
}) => {  
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;   
  const [lng, setLng] = useState();  
  const [qteMsg, setQteMsg] = useState(false); 
  useEffect(() => { 
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);  
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const showMsg = async () => { 
    setQteMsg(true);
      await sleep(5000);
      setQteMsg(false); 
  };
  const changeQteNumPad = (qte) => {
    const stockAvailable = numPadArticle ? numPadArticle?.stockQuantity : numPadArticleCatalog?.stockQuantity;
    (stockAvailable > parseInt(qte)) ? setQteNumPad(qte) : showMsg();
  };
  const onClick = (e) => {
    e.preventDefault(); 
    numPadArticle ? changeQte(numPadArticle,"numPadQte",parseInt(qteNumPad)) :  changeQte(numPadArticleCatalog,"numPadQte",parseInt(qteNumPad));
    onClose();
  };
  return (
    <Dialog className={lng == "ar" ? 'numpad_qte numpad_qte_ar' : 'numpad_qte'} open={open} onClose={onClose} aria-labelledby="form-dialog-title"> 
        {qteMsg &&
        <div className='popup_msg'>
              <img src="/images/i_red.png" alt='' />
              <span>{trans('popupMsg.qte')}</span>
            </div>
            }
        {numPadArticle &&
          <div className='ctn_article'>
        {(numPadArticle?.promotionPercent > 0 && !numPadArticle?.isGift) &&
          <div className='promo'>
            <span>{parseInt(numPadArticle?.promotionPercent)}%</span>
          </div>
          }
          <div className='totale'>
                    <div><span>{(qteNumPad > 1) ? (numPadArticle?.discountedSalesUnitPriceTTC?.toFixed(2) * qteNumPad).toFixed(2) :  numPadArticle?.discountedSalesUnitPriceTTC?.toFixed(2)}</span><span>{trans('dh')}</span></div>
              </div>
        <div className='ctn_img'>
            <img src={numPadArticle?.imageListUrl[0]} alt=''/>
          </div>
          <div className='ctn_inf'>
            <div className='ctn_price'> 
              {numPadArticle?.inPromo ? ( 
                  <> 
                  <span>{numPadArticle?.discountedSalesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                  <span>{numPadArticle?.salesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                </> 
                ) : ( 
                <>
                <span>{numPadArticle?.salesUnitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                <span></span>
                
                </>
                )} 
            </div>
            <div className='ctn_desc'>
              <h5>{lng == "ar" ? numPadArticle?.commercialDesignationAr : numPadArticle?.commercialDesignation}</h5>
            </div>  
              {numPadArticle?.unitText &&
              <div className='ctn_info'>
                <p>{(numPadArticle?.unitText == "Pièce") ? trans(numPadArticle?.unitText) :`${trans(numPadArticle?.unitText)} : ${numPadArticle?.caseItems} ${trans('Pièces')} × ${numPadArticle?.discountedUnitPriceTTC} ${trans('dh')} `}</p>
              </div>
              }
          </div> 
        </div>
        }
        {numPadArticleCatalog &&
          <div className='ctn_article'>
        {numPadArticleCatalog?.inPromo &&
                    <div className='promo'>
                      <span>{parseInt(numPadArticleCatalog?.promotionPercent)}%</span>
                    </div>
                    }
                    <div className='totale'>
                      {numPadArticleCatalog?.inPromo ? ( 
                          <> 
                            <div><span>{(qteNumPad > 1) ?(numPadArticleCatalog?.unitPromotionPriceTTC?.toFixed(2) * qteNumPad).toFixed(2) : numPadArticleCatalog?.unitPromotionPriceTTC?.toFixed(2)}</span><span>{trans('dh')}</span></div>
                          </>
                        ) : (
                          <>
                          <div><span>{(qteNumPad > 1) ? (numPadArticleCatalog?.unitPriceTTC?.toFixed(2) * qteNumPad).toFixed(2) : numPadArticleCatalog?.unitPriceTTC?.toFixed(2)}</span><span>{trans('dh')}</span></div> 
                          </>
                        )}
                    </div>
        <div className='ctn_img'>
            <img src={numPadArticleCatalog?.imageListUrl[0]} alt=''/>
          </div>
          <div className='ctn_inf'>
          <div className='ctn_price'>
                      {numPadArticleCatalog?.inPromo ? ( 
                          <> 
                            <span>{numPadArticleCatalog?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</span>
                            <span>{numPadArticleCatalog?.unitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                          </>
                        ) : (
                          <>
                          <span>{numPadArticleCatalog?.unitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                          <span></span>
                          </>
                        )} 
                    </div>
            <div className='ctn_desc'>
              <h5>{lng == "ar" ? numPadArticleCatalog?.commercialDesignationAr : numPadArticleCatalog?.commercialDesignation}</h5>
            </div>   
              {numPadArticleCatalog?.unitText &&
              <div className='ctn_info'>
               <p>{(numPadArticleCatalog?.unitText == "Pièce") ? trans(numPadArticleCatalog?.unitText) :`${trans(numPadArticleCatalog?.unitText)} : ${numPadArticleCatalog?.caseItems} ${trans('Pièces')} × ${numPadArticleCatalog?.promotionPriceTTC} ${trans('dh')} `}</p>
              </div>
              }
          </div> 
        </div>
        } 
        <div className={"ctn_numpad_qte"}>  
          <Input   
              value={qteNumPad}
              type="phone"
              className="phone_input" 
              onChange={(event) => changeQteNumPad(event.target.value)} 
            /> 
            <div className='login_numpad'>
            <NumPad
              stockAvailable={numPadArticle ? numPadArticle?.stockQuantity : numPadArticleCatalog?.stockQuantity}
              showMsg={showMsg}
              renderNumPad={renderNumPad}
              setRenderNumPad={setRenderNumPad}
              qte={qte}
              value={qteNumPad}
              setValue={setQteNumPad} 
              type='qte'
            /> 
            </div>
            <Button 
              type="button"
              disabled={!(qteNumPad >= 0)}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onClick}
            >
              {trans('confirmer')}
            </Button> 
        </div>
    </Dialog>
  );
}; 
NumPadQte.propTypes = { 
  qteNumPad: PropTypes.number,
  qte: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired, 
  changeQte: PropTypes.func.isRequired, 
  setQteNumPad: PropTypes.func.isRequired, 
  numPadArticle: PropTypes.any, 
  numPadArticleCatalog: PropTypes.any, 
  renderNumPad: PropTypes.bool,
  setRenderNumPad: PropTypes.func.isRequired,
};

export default NumPadQte;
