import React, { useContext, useEffect, useState } from 'react';
import { Typography, Button, Input } from '@material-ui/core'; 
import useStyles from '../styles'; 
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'; 
import useAxios, { POST } from 'hooks/useAxios';
import { Store as SecurityStore } from 'stores/securityStore'; 
import { SIGN_IN_OTP_PAGE } from 'pages/routes';
import { CancelToken, SIGN_IN_PATH } from 'myAxios';
import clsx from 'clsx';
import NumPad from 'utils/components/NumPad';   
import { isPhone } from 'helpers/validators';
import { OrderContext } from 'pages/OrderContext'; 
import Fade from 'react-reveal/Fade';
const SignInPhone = () => {
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode} = useContext(OrderContext); 
  useEffect(() => {  
    setOrderLines();
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
  }, []);
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const classes = useStyles();
  const history = useHistory(); 
  const [phone, setPhone] = useState(''); 
  //===========================================  
  const [enablePhone, setEnablePhone] = useState(false); 
  useEffect(() => { 
    setEnablePhone(isPhone(phone));  
  }, [phone]);
  //===========================================
  const {
    state: { connected },
  } = useContext(SecurityStore);

  useEffect(() => {
    if (connected && history) {
      history.push('/startPage');
    } 
  }, [history, connected]);
  const { call } = useAxios(POST, SIGN_IN_PATH, {
    withProgressBar: true,
  });
  const onSubmit = (e) => {
    e.preventDefault();
 
    const source = CancelToken.source(); 
    const body = {}; 
    body['phoneNumber'] = phone; 
    body['code'] = ""; 
    body['skipSendCode'] = false; 

    call({ body, source }).then((response) => {
      response?.status === 202 && history.push(SIGN_IN_OTP_PAGE.replace(':phone', phone));
    });
    
  };
  return ( 
      <div className={"screen_background screen_background_responsable"}>
        <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
        <Typography component="h1" variant="h5">
        {trans('authentication.p1')}
        </Typography>
        <div className={"ctn_login"}> 
          <div className={clsx(classes.form, 'login_phone')} noValidate>
          <div className='input_icon'>
            <img src='https://i.ibb.co/SPhkKPB/call.png' alt="icon phone"/>
             <Input   
              value={phone}
              type="phone"
              placeholder="06 00  00 00  00"
              className="phone_input" 
              onChange={(event) => setPhone(event.target.value)}
              label={trans('phone')}
              onFocus={() => {
                (phone.length == 0) ? setPhone('0') :  setPhone(phone);
              }} 
            />
            </div> 
            <div className='login_numpad'>  
            <NumPad
              value={phone}
              setValue={setPhone} 
              type='phone'
            /> 
            </div>
            <Button 
              type="button"
              disabled={!enablePhone}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onSubmit}
            >
              {trans('suivantFr')}
            </Button>
          </div>
        </div>
      </div> 
  );
};

export default SignInPhone;
