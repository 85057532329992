import React, { useContext, useEffect, useState } from 'react';      
import PropTypes from 'prop-types';
import { Dialog, DialogActions } from '@material-ui/core';      
import { useTranslation } from 'react-i18next';
import { OrderContext } from 'pages/OrderContext';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import NumPadQte from 'utils/components/numPadQte/NumPadQte';
const PopupSingleArticle = ({ open, onClose, article,getTotalPanier,setChangeQteCount,changeQteCount,addArticleToCart,showMsg,lng,setQteToOrder }) => {  
  const {setOrderLines,orderLines} = useContext(OrderContext);
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;  
  const [idArticlesInPanier, setIdArticlesInPanier] = useState([]);   
  const [idArticlesInPanierQte, setIdArticlesInPanierQte] = useState([]);  
  const [numPadQte, setNumPadQte] = useState(false); 
  const [qteNumPad, setQteNumPad] = useState();  
  const [numPadArticle, setNumPadArticle] = useState();    
  const [qteNumPadQte, setQteNumPadQte] = useState();  
  const [renderNumPad, setRenderNumPad] = useState(false);
  useEffect(() => {  
    if(orderLines){ 
      let preIdArticlesInPanierQte = []; 
      let preIdArticlesInPanier = []; 
      orderLines.map((orderLine) => {
        let objQte = {
          id:orderLine?.articleId,
          qte:orderLine?.quantity,
          price:orderLine?.unitPriceTTC
        }; 
        preIdArticlesInPanierQte.push(objQte);
        preIdArticlesInPanier.push(orderLine.articleId);
        }); 
        setIdArticlesInPanierQte(preIdArticlesInPanierQte);
        setIdArticlesInPanier(preIdArticlesInPanier);
    } 
    getTotalPanier(); 
  }, [orderLines]);  
  const changeQte = (article,type,value = 0) => {  
    let myOrderLines = [];
    let myIdsArticlesInPanierQte = [];
    let myOrderLine ;
    let myIdArticlesInPanierQte ;
    let preIdArticlesInPanierQte = idArticlesInPanierQte;
    let preOrderLines = orderLines;
    let minToOrder = ( article?.minimumToOrder > 0) ? article?.minimumToOrder : 1 ;
      let qteStep = ( article?.quantityStep > 0) ? article?.quantityStep : 1 ;
    myOrderLines =preOrderLines.filter(function( obj ) {
      if(obj.articleId !== article?.articleId){
        return obj;
      }else{
        myOrderLine = obj;
      }
      return obj.articleId !== article?.articleId;
    });
    myIdsArticlesInPanierQte =preIdArticlesInPanierQte.filter(function( obj ) {
      if(obj.id !== article?.articleId){
        return obj;
      }else{
        myIdArticlesInPanierQte = obj;
      }
      return obj.id !== article?.articleId;
    });
    if(type == "plus"){
      if(article?.stockQuantity > myOrderLine.quantity){
        if(myOrderLine.quantity < minToOrder){
          myOrderLine.quantity = minToOrder;
          myIdArticlesInPanierQte.qte = minToOrder;
        }else if(myOrderLine.quantity == minToOrder){
          myOrderLine.quantity += qteStep;
          myIdArticlesInPanierQte.qte += qteStep;
        }else{
          let rest = (myOrderLine.quantity - minToOrder) % qteStep ;
          myOrderLine.quantity += (qteStep - rest);
          myIdArticlesInPanierQte.qte += (qteStep - rest);
        }
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
      }else{ 
        myOrderLine.quantity = myOrderLine.quantity + 0;
        myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte + 0;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
        showMsg("qte");
      }
    }if(type == "minus"){
        if(myOrderLine.quantity > minToOrder){
          if(myOrderLine.quantity >= (minToOrder + qteStep)){
            let rest = (myOrderLine.quantity - minToOrder) % qteStep ;
            if(rest == 0){
              myOrderLine.quantity = myOrderLine.quantity - qteStep;
              myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte - qteStep;
            }else{
              myOrderLine.quantity = myOrderLine.quantity - rest;
              myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte - rest;
            }
          }else{
            myOrderLine.quantity = minToOrder;
            myIdArticlesInPanierQte.qte = minToOrder;
          }
          
          myOrderLines.push(myOrderLine);
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        }else{
          const array = idArticlesInPanier; 

          const index = array.indexOf(article?.articleId);
          if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
          }
          setIdArticlesInPanier(array);   
          showMsg("remove"); 
        }
    }  
    if(type == "numPadQte"){ 
      if(value <= 0){
           const array = idArticlesInPanier; 
           const index = array.indexOf(article?.articleId);
            if (index > -1) {
              array.splice(index, 1);
            }
            setIdArticlesInPanier(array);
            showMsg("remove"); 
      }
      else if(article?.stockQuantity > value){
        if(value < minToOrder){
          myOrderLine.quantity = minToOrder;
          myIdArticlesInPanierQte.qte = minToOrder;
          setQteToOrder(minToOrder);
          showMsg("minToOrder");
        }else{
          let rest = (value - minToOrder) % qteStep ;
          myOrderLine.quantity = (value - rest);
          myIdArticlesInPanierQte.qte = (value - rest);
        } 
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
      }else{ 
        myOrderLine.quantity = article?.stockQuantity;
        myIdArticlesInPanierQte.qte = article?.stockQuantity;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
        showMsg("qte");
      }
  }
    setOrderLines(myOrderLines);
    setChangeQteCount(changeQteCount + 1); 
    setIdArticlesInPanierQte(myIdsArticlesInPanierQte);
  }; 
  const addArticleToCartt = () => {  
    addArticleToCart(article); 
  }; 
  const onCloseNumPad = () => {  
    setQteNumPad();
    setNumPadQte(false);
  }; 
  const changeQteNumPad = (article,qte) => {  
    setNumPadArticle(article); 
    setQteNumPadQte(qte);  
    setRenderNumPad(false);   
    setNumPadQte(true);
  };
  return (
    <>
    <NumPadQte  
          setRenderNumPad={setRenderNumPad}
          renderNumPad={renderNumPad}
          setQteNumPad={setQteNumPad}
          qteNumPad={qteNumPad}
          qte={qteNumPadQte}
          open={numPadQte}
          numPadArticleCatalog={numPadArticle} 
          onClose={() => onCloseNumPad()} 
          changeQte={(article,type,value) => changeQte(article,type,value)} 
        > 
        </NumPadQte>
      <Dialog open={open} onClose={onClose} className={lng == "ar" ? "popup_single_article_ar popup_single_article" :  "popup_single_article"}> 
        <DialogActions>
           <div className='single_article'>
              <div className='ctn_img'>
              <Carousel
              autoPlay={true}
              emulateTouch={true}
              >
              {article?.imageListUrl?.map((item,index) => (
              <div  key={index}>
                    <img src={item} /> 
                </div>
                ))}
                </Carousel>  
                  </div>
                  {article?.articlePromotionInfo?.paliers?.length > 0 ? ( 
                          <> 
                          <div className='ctn_inf'>
                             <div className='ctn_desc'>
                                <h5>{lng == "ar" ? article?.commercialDesignationAr : article?.commercialDesignation}</h5>
                              </div> 
                          </div> 
                          {article?.unitText && 
                              <div className='ctn_info'>
                                <p>{(article?.unitText == "Pièce") ? trans('articleUnt') : trans('articleCaisse', { qte: article?.caseItems })}</p>
                              </div>
                              } 
                              <div className='ctn_bottom_info'>
                              <div className='left'>
                                <div>
                              {article?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p>{trans('palierCaisse', { qte: article?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                        <p>{article?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                        <p>{`${article?.caseItems} × ${article?.articlePromotionInfo?.paliers[0]?.unitPrice?.toFixed(2)}  ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                        <p>{trans('palierUnt', { qte: article?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                        <p>{article?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                        <p></p>
                                      </>
                                    )} 
                                    </div>
                              </div>
                              <div className='right'>
                              <div>
                              {(article?.inPromo && article?.promotionPercent > 0) ? ( 
                                  <> 
                                  {article?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{article?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                        <p>{`${article?.caseItems} × ${(article?.unitPromotionPriceTTC / article?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{article?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                          <p></p>
                                        </>
                                    )} 
                                  </>
                                ) : (
                                  <>
                                    {article?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p className='no_line_through'><span>5</span></p>
                                        <p>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{`${article?.caseItems} × ${(article?.unitPriceTTC / article?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p></p>
                                          <p>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p></p>
                                        </>
                                    )} 
                                  
                                  </>
                                )} 
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                             <div className='ctn_inf'>
                              <div className='ctn_desc'>
                                <h5>{lng == "ar" ? article?.commercialDesignationAr : article?.commercialDesignation}</h5>
                              </div>
                              <div className='ctn_price'>
                                {(article?.inPromo && article?.promotionPercent > 0) ? ( 
                                    <> 
                                      <span>{article?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</span>
                                      <span>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                                    </>
                                  ) : (
                                    <>
                                    <span>{article?.unitPriceTTC?.toFixed(2)} {trans('dh')}</span>
                                    <span></span>
                                    </>
                                  )} 
                              </div> 
                              </div>
                              {article?.unitText &&
                                <div className='ctn_info'>
                                  <p>{(article?.unitText == "Pièce") ? trans(article?.unitText+'Fiche') :`${trans(article?.unitText+'Fiche')} : ${article?.caseItems} ${trans('Pièces')} × ${article?.promotionPriceTTC} ${trans('dhUnite')} `}</p>
                                </div>
                                }
                          </>
                        )}
                    {article?.articlePromotionInfo?.gifts?.length > 0 ? (
                          <>
                            <div className='ctn_gift'>
                              <div className='gift'>
                              <div className='bloc1'>
                                <p>{trans('giftP1', { qte: article?.articlePromotionInfo?.gifts[0]?.minimum })}</p>
                              </div>
                              <div className='bloc2'>
                                <p>=</p>
                              </div>
                              <div className='bloc3'>
                              <p>
                                  {
                                    (article?.articlePromotionInfo?.gifts[0]?.giftQuantity > 1 ) ? trans('giftSingleArticleP3', { qte: article?.articlePromotionInfo?.gifts[0]?.giftQuantity }) : trans('giftSingleArticleP2', { qte: article?.articlePromotionInfo?.gifts[0]?.giftQuantity }) 
                                  } 
                                </p>
                                <p>{ lng == "ar" ? article?.articlePromotionInfo?.gifts[0]?.article?.designationAr : article?.articlePromotionInfo?.gifts[0]?.article?.designationFr}</p>
                              </div>
                              <div className='bloc4'><img src={article?.articlePromotionInfo?.gifts[0]?.article?.image}/></div>
                              </div>
                            </div>
                          </>
                           ) : (
                           <>
                           </>
                           )}
                  {(article?.inPromo && article?.promotionPercent > 0) &&
                    <div className='promo'>
                      <span>-{parseInt(article?.promotionPercent)}%</span>
                    </div>
                    }
                  <div className='close'>
                    <div onClick={() => onClose()} >
                      <img src='https://i.ibb.co/ccMpf5N/close-1.png' alt=''/>
                    </div>
                  </div>
                  {idArticlesInPanier.includes(article?.articleId) &&  article?.stockQuantity > 0 &&
                  <div className='ctn_qte'> 
                    <div className='qte qte_horizontal'>
                      <div className='ctn'>
                        <div className='plus' onClick={() => changeQte(article,'plus')}>
                          <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
                        </div>
                        {idArticlesInPanier.includes(article?.articleId) &&
                          <input type="text" onClick={() => changeQteNumPad(article,idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte)} onChange={() => 1} value={idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte} />
                        }
                        <div className='minus' onClick={() => changeQte(article,'minus')}>
                          <img src='https://i.ibb.co/9p0Rtcp/remove.png' alt=''/>
                        </div>
                      </div>
                    </div>
                    <div className='total_price'>
                    {(article?.articlePromotionInfo?.paliers?.length > 0 && idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte >= article?.articlePromotionInfo?.paliers[0]?.minimum)
                    ?
                    <>
                    <span>{ (article?.articlePromotionInfo?.paliers[0]?.casePrice * idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte).toFixed(2)} {trans('dh')}</span>
                    </>
                    :
                    (article?.inPromo && article?.promotionPercent > 0) ? ( 
                          <> 
                            <span>{ (idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte * article?.unitPromotionPriceTTC).toFixed(2)} {trans('dh')}</span>
                          </>
                        ) : (
                          <>
                          <span>{ (idArticlesInPanierQte?.find(obj => { return obj.id === article?.articleId }).qte * article?.unitPriceTTC).toFixed(2)} {trans('dh')}</span> 
                          </>
                        )}
                    </div>
                  </div>
                  }
                  {!idArticlesInPanier.includes(article?.articleId) &&  article?.stockQuantity > 0 &&
                  <div className='ctn_btn'> 
                    <div onClick={() => addArticleToCartt()} className='btn_order add_order'>
                      <span>{trans('panier.ajouterPanier')}</span>
                    </div>
                  </div>
                  }
                  <div className='code'>
                    <p>{`${trans('code')} : ${article?.code}`}</p>
                  </div>
           </div>
        </DialogActions>
      </Dialog> 
    </>
  );
};
PopupSingleArticle.propTypes = {
  open: PropTypes.bool,
  changeQteCount: PropTypes.number,
  lng: PropTypes.any,
  onClose: PropTypes.func.isRequired,        
  getTotalPanier: PropTypes.func.isRequired,        
  setChangeQteCount: PropTypes.func.isRequired,        
  setQteToOrder: PropTypes.func.isRequired,        
  addArticleToCart: PropTypes.func.isRequired,        
  showMsg: PropTypes.func.isRequired,        
  article: PropTypes.object,
}; 
export default PopupSingleArticle;
