import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ButtonBack = ({ link , icon }) => {    
  const { i18n } = useTranslation(); 
  const history = useHistory(); 
  const {t: trans} = i18n;
  const onClickBack = (e) => {
    e.preventDefault(); 
    history.push(link); 
  };
  return (
    <div className="ctn_btn_back">
      <IconButton
      className={icon ? "btn_back_icon" : ""}
        onClick={onClickBack}
        aria-label="delete">
        <ArrowBackIcon />
        {icon ? trans('') : trans('retour')}
      </IconButton> 
      </div>
  );
};  
ButtonBack.propTypes = {     
  link: PropTypes.string,   
  icon: PropTypes.bool,   
};

export default ButtonBack;
