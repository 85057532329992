import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    homeContainer: {
      padding: 5,
      marginTop: 20,
      justifyContent: 'center',
    },
    '@keyframes animatedImage': {
      from: { opacity: 1 },
      to: { opacity: 0.25 },
    },
    homeCard: {
      '& .content': {
        cursor: 'pointer',
        backgroundColor: '#11304D',
        border: '1px solid #fb7f08',
        padding: '5px 5px 0px 5px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: 5,
        position: 'relative',
        '& .label': {
          width: '100%',
          position: 'absolute',
          top: '25%',
          display: 'none',
          fontSize: 24,
          '& img': {
            width: 50,
            backgroundColor: '#fff',
            padding: 10,
            borderRadius: '50%',
          },
        },
        '& img': {
          width: '100%',
        },
        '& img:hover': {
          homeCardLabel: {
            dislay: 'block',
          },
        },
      },
      '& .content:hover': {
        '& > img': {
          animation: '$animatedImage .5s ease forwards',
        },
        '& .label': {
          display: 'block',
        },
      },
    },
    formContainer: {
      padding: '1rem',
      '& .MuiInputBase-input': {
        color: '#555 !important',
      },
      width: '100%',
    },
    row: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      alignItems: 'flex-start',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    divider: {
      margin: theme.spacing(3),
      flex: 1,
    },
    title: {
      alignSelf: 'flex-start',
      marginBottom: theme.spacing(3),
    },
    imageUploader: {
      border: '1px solid whitesmoke',
      borderRadius: '3px',
      margin: theme.spacing(1),
    },
    confButton: {
      margin: theme.spacing(1),
    },
    buttonPosition: {
      float: 'right',
    },
    dataGridDiv: { height: 400, width: '100%' },
    button: {
      color: theme.palette.secondary.main,
      margin: 10,
      backgroundColor: '#e0e0e061',
    },
    input: {
      display: 'none',
    },
    actions: {
      justifyContent: 'flex-end',
      display: 'flex',
    },
    textCenter: {
      textAlign: 'center',
    },
    addbtn: {
      color: '#ffffff !important',
      float: 'right !important',
    },
    updateSelection: {
      '& .MuiTablePagination-root:last-child': {
        display: 'none',
      },
      '& .subSelectionData': {
        height: 'auto',
      },
    },
    userParamsCard: {
      height: 'auto',
      marginBottom: 15,
    },
    ctnDropdown: {
      marginBottom: 15,
      width: '60%',
      margin: '0 auto',
      '& .MuiAutocomplete-fullWidth': {
        marginBottom: 10,
      },
    },
    onlySearch: {
      width: 300,
    },
    capitalizedBtn: {
      textTransform: 'none',
    },
    ctnAffectation: {
      float: 'left',
      width: '50%',
      '& .ctn_btn': {
        '& .MuiButton-label': {
          color: 'white',
        },
      },
      '& .MuiGrid-container': {
        '& .MuiGrid-item': {
          '& .MuiPaper-root': {
            '& .MuiTypography-body2': {
              fontSize: 11,
            },
          },
          '& .MuiList-root': {
            width: 180,
          },
          '& .MuiButton-root': {
            padding: '4px 11px',
            fontSize: 14,
            minWidth: 40,
          },
        },
      },
    },
    PageTitleSpacing: {
      paddingLeft: '5px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    scheduleStatus: {
      padding: 5,
      borderRadius: '50%',
    },
    scheduleStatusList: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiListItem-root': {
        justifyContent: 'flex-end !important',
        padding: 0,
        '& > span': {
          padding: 5,
          borderRadius: '50%',
          marginRight: 5,
        },
      },
    },
    provScheduleStatusBox: {
      width: 250,
    },
    loadedScheduleStatusBox:{
      width: 350,
    },
    ctnProfile:{
      height:'400px',
      position:'relative', 
      '& .actions': {
        position: 'absolute', 
        top: '0',
        right: '0',
      },
      '& .button': {
        backgroundColor: 'white', 
        color: '#11304d', 
      },
    },
    ctnImgProfileTop:{
      height:'150px', 
      backgroundColor:'#8c8696',
    },
    ctnImgProfileBottom:{
      height:'205px', 
      padding:'85px 0 0 0', 
      textAlign:'center', 
      '& p': {
        fontSize: '16px',
        letterSpacing: '1px',
        fontWeight: 'bold',
      },
    },
    imgProfile: { 
      height:'100%',
      width:'100%',
    },
    ctnImgProfile: {
      position: 'absolute',
      top: '100px', 
      left: '0', 
      right: '0', 
      marginLeft: 'auto', 
      marginRight: 'auto', 
      width: '140px',
      height: '140px',
      border: '3px solid white',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    ctnInfo: { 
      margin:'35px 0', 
    }, 
    OrderLinesTable: { 
      '& .MuiTablePagination-root': {
        display: 'none', 
      },
    }, 
    cardTop: { 
      marginBottom : '20px',
    }, 
    infoCustomer: {  
      lineHeight: '80px',
      '& p': {
        fontSize: '22px', 
        margin: '0 30px', 
        color: '#11304d', 
      },
      '& span': {
        color: '#ea5d34', 
      },
    }, 
    orderModal:{
      '& h3':{
        marginTop: 5,
      },
      '& .updateBtn':{
        padding: 9,
        fontSize: 12,
      },
      '& .orderLinesTable':{
        marginTop: 10,
        '& tbody':{
          overflowY: 'auto',
        },
        '& thead':{
          position: 'sticky',
          top: 0,
          backgroundColor: '#eee',
          zIndex: 1000,
        },
      },
      '& > .MuiDialog-container ':{
        '& > .MuiPaper-root':{
          width: 550,
          height: 600,
          padding: 25,
          overflowX: 'hidden'
        }
      },
      '& .MuiTypography-root':{
        fontSize: 16,
        margin: 'auto',
      }
    },
    withoutPagination:{
          '& .MuiTablePagination-root':{
            display:'none',
          }
    },
    vehiculeDropDownLabel:{
      borderBottom: '1px #ccc solid',
      width: '100%',
      '& .dropdown-title':{

      },
      '& .dropdown-subtitle':{
        fontSize: 12,
        color: 'rgba(0, 0, 0, .5)'
      }
    }
  };
});

/** Old CSS

.update_selection .subselectionsdata > div:nth-child(2) {
  height: auto;
}
.update_selection .MuiTablePagination-root:last-child {
  display: none;
}

**/
