import React, { useEffect, useState ,useContext } from 'react'; 
import "react-simple-keyboard/build/css/index.css";
import StoreContainer from 'pages/StoreContainer';  
import { useTranslation } from 'react-i18next'; 
import PopupSingleArticle from './PopupSingleArticle';  
import { useHistory } from 'react-router-dom';
import { OrderContext } from 'pages/OrderContext';
import { BANNERS_PATH, CancelToken,CATALOGUEAPI, PROMOTIONS } from 'myAxios'; 
import useAxios, { GET } from 'hooks/useAxios';
import { customerStatus } from 'consts';
import Fade  from 'react-reveal/Fade';
import Bounce  from 'react-reveal/Bounce';
import { Waypoint } from 'react-waypoint'; 
import ConfirmationDialog from 'utils/components/ConfirmationDialog'; 
import { getDistance } from 'geolib'; 
import NumPadQte from 'utils/components/numPadQte/NumPadQte';
import PromotionDialog from './PromotionDialog';
import storage from 'helpers/storage';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const Catalogue = () => {  
  const {customer,setOrderLines,orderLines,deliveryMode, setDeliveryMode,customerPhone,setSlotReceipt,showPopup, setShowPopup} = useContext(OrderContext);
  const history = useHistory(); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n; 
  const store = storage.getStore();
  const [families, setFamilies] = useState([]);
  const [brands, setBrands] = useState([]); 
  const [blocs, setBlocs] = useState([]);  
  const [idBrands, setIdBrands] = useState([]);  
  const [idArticlesInPanier, setIdArticlesInPanier] = useState([]);   
  const [idArticlesInPanierQte, setIdArticlesInPanierQte] = useState([]);  
  const [selectedBloc, setSelectedBloc] = useState();  
  const [selectedFamily, setSelectedFamily] = useState('');  
  const [singleArticle, setSingleArticle] = useState();    
  const [lng, setLng] = useState();   
  const [changeQteCount, setChangeQteCount] = useState(0);   
  const [idBloc, setIdBloc] = useState(0);   
  const [totalPanier, setTotalPanier] = useState(0);  
  const [addMsg, setAddMsg] = useState(false);   
  const [removeMsg, setRemoveMsg] = useState(false);   
  const [minToOrder, setMinToOrder] = useState(false);   
  const [qteToOrder, setQteToOrder] = useState(0);   
  const [qteMsg, setQteMsg] = useState(false);   
  const [catalog, setCatalog] = useState(0);  
  const [openCancel, setOpenCancel] = useState(false); 
  const [openPromo, setOpenPromo] = useState(false); 
  const [randomNumber, setRandomNumber] = useState(false); 
  const [numPadQte, setNumPadQte] = useState(false); 
  const [qteNumPad, setQteNumPad] = useState();  
  const [numPadArticle, setNumPadArticle] = useState();  
  const [openExcludingDelivery, setOpenExcludingDelivery] = useState(false); 
  const [qteNumPadQte, setQteNumPadQte] = useState();  
  const [renderNumPad, setRenderNumPad] = useState(false);
  const [brandClick, setBrandClick] = useState(false);
  const [listArticlesPromo, setListArticlesPromo] = useState();
  const [showPromo, setShowPromo] = useState(false);
  const loader = document.querySelector('#ctn_loader'); 
  /*=========================*/
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);  
  /*=========Get Catalogue=============*/
  const {
    data, 
    call,  
  } = useAxios(GET, CATALOGUEAPI, {
    withProgressBar: true,
  });
  const {
    data : dataPromo, 
    call : callPromo,  
  } = useAxios(GET, PROMOTIONS, {
    withProgressBar: true,
  });
  const { data: { collection  } = {}, call : callBanners } = useAxios(GET, BANNERS_PATH, {
    withProgressBar: true,
  });   
  useEffect(() => {    
    setSlotReceipt(null);
    setRandomNumber(Math.random());
  }, []); 
  useEffect(() => {    
    if(customer){ 
      const source = CancelToken.source(); 
      call({
        params: {
          timestamp:new Date().getTime(),
          customerId: customer.customerId,
          deliveryModeId : (deliveryMode == "To Go") ? 1 : 2
        },
        source,
      });  
      callPromo({
        params: {
          timestamp:new Date().getTime(),
          customerId: customer.customerId,
          deliveryModeId : (deliveryMode == "To Go") ? 1 : 2
        },
        source,
      });  
      callBanners({
        params: {
          take: 1000, 
          page: 1,
          channelId :customer?.channelId,
          warehouseId :store?.warehouseId,
        },
        source,
      });  
      return source.cancel; 
    }  
  }, [randomNumber]);
  useEffect(() => {     
    
    if(data){   
      loader.classList.remove("ctn_loader_show"); 
      setCatalog(data); 
      if(dataPromo?.length > 0){
        setListArticlesPromo(dataPromo);
        setShowPromo(true)
        showPromotion(); 
      } 
    }else{
      loader.classList.add("ctn_loader_show");
    }
  }, [randomNumber,data,dataPromo]);   
  /*=========================*/
  /*=========================*/ 
  useEffect(() => {  
    if(store && customer){   
      if(deliveryMode == "To Deliver" && customer.customerStatusId != customerStatus.EN_ATTENTE){
      let distance = getDistance(
          { latitude: store.latitude, longitude: store.longitude },
          { latitude: customer.latitude, longitude: customer.longitude }
        ); 
        if(distance > store.perimeter ){ 
          setOpenExcludingDelivery(true);
        }
        
      }
    }
    
  }, [store,customer]);  
  /*=========================*/
  /*=========================*/
  useEffect(() => {  
    let preFamilies = []; 
    let preBrands = []; 
    let preIdBrands = []; 
    if (catalog) {
      catalog.map((allCatalog) => {
      preFamilies.push({
        familyId: allCatalog?.familyId,  
        imageUrl: allCatalog?.imageUrl,  
        description: allCatalog?.description,  
        descriptionAr: allCatalog?.descriptionAr,  
      });
    }); 
    catalog[0].brands.map((catalogBrands) => {
      preIdBrands.push(catalogBrands?.brandId);
      preBrands.push({
        brandId: catalogBrands?.brandId,  
        imageUrl: catalogBrands?.imageUrl,  
        description: catalogBrands?.description,  
      });
    }); 
    setIdBrands(preIdBrands);
    setFamilies(preFamilies);
    setBrands(preBrands);
    setBlocs(catalog[0].brands);
    setSelectedFamily(lng == "ar" ? catalog[0].descriptionAr : catalog[0].description);
  } 
  }, [catalog]);
  /*=========================*/
  /*=========================*/
  useEffect(() => {  
    if(selectedBloc){ 
    let preBrands = []; 
    let preIdBrands = []; 
    let preBloc ;  
    if (catalog) {  
      catalog.map((ca) => {
        if(ca.familyId == selectedBloc){
          setSelectedFamily(lng == "ar" ? ca.descriptionAr : ca.description);
          preBloc = ca.brands;
          setIdBloc(ca.brands[0].brandId);
          ca.brands.map((catalogBrands) => {
            preIdBrands.push(catalogBrands?.brandId);
            preBrands.push({
              brandId: catalogBrands?.brandId,  
              imageUrl: catalogBrands?.imageUrl,  
              description: catalogBrands?.description,  
            });
          });
        } 
      });
    } 
    setIdBrands(preIdBrands);
    setBrands(preBrands);
    setBlocs(preBloc); 
    }  
  }, [selectedBloc]); 
  /*=========================*/
  /*=========================*/ 
  const setSelectedBrand = async (selectedBrand) => {
    if(selectedBrand){  
      setBrandClick(true);
      const element = document.getElementById(selectedBrand);
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      } 
      var elems = document.querySelectorAll(".brand_selected"); 
      [].forEach.call(elems, function(el) {
          el.classList.remove("brand_selected");
      }); 
      document.getElementsByName(selectedBrand)[0].classList.add("brand_selected"); 
    } 
  };
  const clickFamily = (selectedFamily) => { 
    if(selectedFamily){  
      setSelectedBloc(selectedFamily);
      var elems = document.querySelectorAll(".family_selected"); 
      [].forEach.call(elems, function(el) {
          el.classList.remove("family_selected");
      }); 
      document.getElementsByName(selectedFamily)[0].classList.add("family_selected");
    } 
  };
  /*=========================*/
  /*=========================*/
  const onClickArticle = (item) => { 
    if(item){  
      setSingleArticle(item);
    } 
  };
  /*=========================*/
  /*=========================*/
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const showMsg = async (type) => { 
    setAddMsg(false);
    setRemoveMsg(false);
    setQteMsg(false);
    setMinToOrder(false);
    if(type == "add"){ 
      setAddMsg(true); 
      await sleep(3000);
      setAddMsg(false);
    }
    if(type == "remove"){
      setRemoveMsg(true);
      await sleep(3000);
      setRemoveMsg(false);
    }
    if(type == 'qte'){
      setQteMsg(true);
      await sleep(3000);
      setQteMsg(false); 
    }
    if(type == 'minToOrder'){
      setMinToOrder(true);
      await sleep(3000);
      setMinToOrder(false); 
    }
  };
  const showPromotion = async () => { 
    if(showPopup){
      await sleep(1000);  
      setOpenPromo(true);
      setShowPopup(false);
    }
    
  };
  /*=========================*/
  /*=========================*/
  const addArticleToCart = (item) => {  
    if(!idArticlesInPanier.includes(item?.articleId)){  
      let minToOrder = ( item?.minimumToOrder > 0) ? item?.minimumToOrder : 1 ;
      let qteStep = ( item?.quantityStep > 0) ? item?.quantityStep : 1 ;
      let objQte = {
        id:item?.articleId,
        qte:minToOrder
      };
      let preOrderLines = [];
      let preIdArticlesInPanier = [];  
      let preIdArticlesInPanierQte = idArticlesInPanierQte;  
      preIdArticlesInPanierQte.push(objQte);
      if(orderLines){ 
        orderLines.map((orderLine) => {
          preOrderLines.push(orderLine); 
          }); 
      } 
      if(idArticlesInPanier){ 
        idArticlesInPanier.map((id) => {
          preIdArticlesInPanier.push(id); 
          }); 
      } 
      let article = {};
      article.articleId = item.articleId;
      article.code = item.code;
      article.imageListUrl = item.imageListUrl;
      article.commercialDesignation = item.commercialDesignation;
      article.commercialDesignationAr = item.commercialDesignationAr;
      article.unitText = item.unitText;
      article.caseItems = item.caseItems;
      article.promotionPriceTTC = item.promotionPriceTTC;
      article.orderLineId = 0;
      article.quantity = minToOrder; 
      article.inPromo = item.inPromo;
      article.unitPromotionPriceTTC = item.unitPromotionPriceTTC;
      article.unitPriceTTC = item.unitPriceTTC;
      article.uvcPromotionPriceTTC = item.uvcPromotionPriceTTC;
      article.promotionPercent = item.promotionPercent; 
      article.discountedSalesUnitPriceTTC = item.unitPromotionPriceTTC;
      article.salesUnitPriceTTC = item.unitPriceTTC;
      article.stockQuantity = item.stockQuantity;
      article.minimumToOrder = minToOrder;
      article.quantityStep = qteStep;
      article.paliers = item.articlePromotionInfo?.paliers;
      article.isGift = false;
      preOrderLines.push(article); 
      preIdArticlesInPanier.push(item.articleId);
      setOrderLines(preOrderLines);
      setIdArticlesInPanier(preIdArticlesInPanier);
      setIdArticlesInPanierQte(preIdArticlesInPanierQte);
      showMsg("add");
    } 
  }; 
  const onClickPanier = () => {  
    if(orderLines){ 
      history.push('/panier'); 
    } 
  };
  const changeQte = (article,type,value = 0) => {  
    let myOrderLines = [];
    let myIdsArticlesInPanierQte = [];
    let myOrderLine ;
    let myIdArticlesInPanierQte ;
    let preIdArticlesInPanierQte = idArticlesInPanierQte;
    let preOrderLines = orderLines;
    let minToOrder = ( article?.minimumToOrder > 0) ? article?.minimumToOrder : 1 ;
    let qteStep = ( article?.quantityStep > 0) ? article?.quantityStep : 1 ;
    myOrderLines =preOrderLines.filter(function( obj ) {
      if(obj.articleId !== article?.articleId){
        return obj;
      }else{
        myOrderLine = obj;
      }
      return obj.articleId !== article?.articleId;
    });
    myIdsArticlesInPanierQte =preIdArticlesInPanierQte.filter(function( obj ) {
      if(obj.id !== article?.articleId){
        return obj;
      }else{
        myIdArticlesInPanierQte = obj;
      }
      return obj.id !== article?.articleId;
    }); 
    if(type == "plus"){
      if(article?.stockQuantity > myOrderLine.quantity){
        if(myOrderLine.quantity < minToOrder){
          myOrderLine.quantity = minToOrder;
          myIdArticlesInPanierQte.qte = minToOrder;
        }else if(myOrderLine.quantity == minToOrder){
          myOrderLine.quantity += qteStep;
          myIdArticlesInPanierQte.qte += qteStep;
        }else{
          let rest = (myOrderLine.quantity - minToOrder) % qteStep ;
          myOrderLine.quantity += (qteStep - rest);
          myIdArticlesInPanierQte.qte += (qteStep - rest);
        }
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
      }else{ 
        myOrderLine.quantity = myOrderLine.quantity + 0;
        myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte + 0;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
        showMsg("qte");
      } 
    }if(type == "minus"){
        if(myOrderLine.quantity > minToOrder){
          if(myOrderLine.quantity >= (minToOrder + qteStep)){
            let rest = (myOrderLine.quantity - minToOrder) % qteStep ;
            if(rest == 0){
              myOrderLine.quantity = myOrderLine.quantity - qteStep;
              myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte - qteStep;
            }else{
              myOrderLine.quantity = myOrderLine.quantity - rest;
              myIdArticlesInPanierQte.qte = myIdArticlesInPanierQte.qte - rest;
            }
          }else{
            myOrderLine.quantity = minToOrder;
            myIdArticlesInPanierQte.qte = minToOrder;
          }
          
          myOrderLines.push(myOrderLine);
          myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        }else{
          const array = idArticlesInPanier; 

          const index = array.indexOf(article?.articleId);
          if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
          }
          setIdArticlesInPanier(array);
          showMsg("remove");   
        }
    }  
    if(type == "numPadQte"){
      if(value <= 0){
           const array = idArticlesInPanier; 
           const index = array.indexOf(article?.articleId);
            if (index > -1) {
              array.splice(index, 1);
            }
            setIdArticlesInPanier(array);
            showMsg("remove"); 
      }
      else if(article?.stockQuantity > value){
        if(value < minToOrder){
          myOrderLine.quantity = minToOrder;
          myIdArticlesInPanierQte.qte = minToOrder;
          setQteToOrder(minToOrder);
          showMsg("minToOrder");
        }else{
          let rest = (value - minToOrder) % qteStep ;
          myOrderLine.quantity = (value - rest);
          myIdArticlesInPanierQte.qte = (value - rest);
        } 
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
      }else{ 
        myOrderLine.quantity = article?.stockQuantity;
        myIdArticlesInPanierQte.qte = article?.stockQuantity;
        myIdsArticlesInPanierQte.push(myIdArticlesInPanierQte);
        myOrderLines.push(myOrderLine);
        showMsg("qte");
      }
  }
    setOrderLines(myOrderLines);
    setChangeQteCount(changeQteCount + 1); 
    setIdArticlesInPanierQte(myIdsArticlesInPanierQte);
  }; 
  /*=========================*/
  /*=========================*/ 
  const getTotalPanier = () => { 
    if(orderLines){ 
      let total = 0;
      orderLines.map((orderLine) => {
        if(orderLine.paliers?.length > 0 && orderLine.quantity >= orderLine?.paliers[0]?.minimum){
          total = (total + (orderLine.quantity * orderLine?.paliers[0]?.casePrice));
        }else{
          total = total + (orderLine.quantity * orderLine.discountedSalesUnitPriceTTC);
        } 
      });
      setTotalPanier(total);
    }
    
  }; 
  /*=========================*/
  /*=========================*/
  useEffect(() => {  
    if(orderLines){ 
      let preIdArticlesInPanierQte = []; 
      let preIdArticlesInPanier = []; 
      orderLines.map((orderLine) => {
        let objQte = {
          id:orderLine?.articleId,
          qte:orderLine?.quantity
        }; 
        preIdArticlesInPanierQte.push(objQte);
        preIdArticlesInPanier.push(orderLine.articleId);
        }); 
        setIdArticlesInPanierQte(preIdArticlesInPanierQte);
        setIdArticlesInPanier(preIdArticlesInPanier);
    } 
    getTotalPanier();  
  }, [orderLines]);  
  /*=========================*/
  /*=========================*/
  useEffect(() => {    
    const element = document.getElementById(idBloc);
    if (element) { 
      element.scrollIntoView({ behavior: 'smooth' });
    } 
  }, [idBloc]);  
  /*=========================*/
  /*=========================*/  
  const onScrollBloc = async (selectedBloc,currentPosition,type) => {  
    if(!brandClick){
      if(selectedBloc){
        if(currentPosition.currentPosition == "above") {
          var elems = document.querySelectorAll(".brand_selected"); 
          [].forEach.call(elems, function(el) {
            el.classList.remove("brand_selected");
        }); 
        document.getElementsByName(idBrands[idBrands.indexOf(selectedBloc)+1])[0].classList.add("brand_selected");
        } 
        if(currentPosition.currentPosition == "below") { 
          var elemms = document.querySelectorAll(".brand_selected"); 
          [].forEach.call(elemms, function(el) {
            el.classList.remove("brand_selected");
        }); 
        document.getElementsByName(idBrands[idBrands.indexOf(selectedBloc)-1])[0].classList.add("brand_selected");
        }  
        console.log('type',type);
      } 
    }else{
      await sleep(600);
      setBrandClick(false);
    }
     
  };
  /*=========================*/
  /*=========================*/
  const cancel = () => { 
    history.push("/startPage"); 
  };
  const changeModeDelivery = () => { 
    setDeliveryMode("To Go");
    setOpenExcludingDelivery(false);
  };
  const onCloseNumPad = () => {  
    setQteNumPad();
    setNumPadQte(false);
  }; 
  const changeQteNumPad = (article,qte) => {  
    setNumPadArticle(article);  
    setQteNumPadQte(qte);  
    setRenderNumPad(false); 
    setNumPadQte(true);
  }; 
    return (
      <>
      <StoreContainer>
      <>
      <PromotionDialog  
          openPromo={openPromo}  
          idArticlesInPanierQte={idArticlesInPanierQte}  
          idArticlesInPanier={idArticlesInPanier}  
          listArticlesPromo={listArticlesPromo}  
          changeQte={(article,type,value) => changeQte(article,type,value)} 
          onClickArticle={(article) => onClickArticle(article)} 
          changeQteNumPad={(article,qte) => changeQteNumPad(article,qte)} 
          addArticleToCart={(e) => addArticleToCart(e)}
          onClosePromo={() => setOpenPromo(false)} 
        > 
        </PromotionDialog>
      <NumPadQte  
          setRenderNumPad={setRenderNumPad}
          renderNumPad={renderNumPad}
          qte={qteNumPadQte}
          setQteNumPad={setQteNumPad}
          qteNumPad={qteNumPad}
          open={numPadQte}
          numPadArticleCatalog={numPadArticle} 
          onClose={() => onCloseNumPad()} 
          changeQte={(article,type,value) => changeQte(article,type,value)} 
        > 
        </NumPadQte>
      <ConfirmationDialog 
          textLeft={trans('popupMsg.oui')}
          textRight={trans('popupMsg.non')}
          open={openCancel}
          onClose={() => setOpenCancel(false)}
          onSuccess={() => cancel()}
        >
          {trans('popupMsg.abandonner')}
        </ConfirmationDialog>
        <ConfirmationDialog 
          textLeft={trans('popupMsg.NonMerci')}
          textRight={trans('popupMsg.OuiBienSur')}
          open={openExcludingDelivery}
          onClose={() => changeModeDelivery()}
          onSuccess={() => cancel()}
        >
          {trans('popupMsg.horsZone')}
        </ConfirmationDialog>
        <div className={lng == "ar" ? "ctn_catalogue ctn_catalogue_ar" :  "ctn_catalogue"}> 
          <div className='catalogue_top'>
            <div className={(addMsg || removeMsg || qteMsg || minToOrder)? 'dialog_msg dialog_msg_bloc' : 'dialog_msg'}> 
              <div className={(addMsg)? "msg_bloc info_msg" : "info_msg"}>
                <img src="images/msg_add.png" alt='' />
                <span>{trans('popupMsg.addArticle')}</span>
              </div> 
              <div className={(removeMsg)? "msg_bloc info_msg" : "info_msg"}>
                <img src="images/msg_delete.png" alt='' />
                <span>{trans('popupMsg.deleteArticle')}</span>
              </div>
              <div className={(minToOrder)? "msg_qte msg_bloc msg_min" : "msg_qte"}>
                <img src="images/i_red.png" alt='' />
                <span>{trans('popupMsg.minOrder', { qte: qteToOrder })}</span>
              </div> 
              <div className={(qteMsg)? "msg_qte msg_bloc msg_min" : "msg_qte"}>
                <img src="images/i_red.png" alt='' />
                <span>{trans('popupMsg.qte')}</span>
              </div>
            </div>
            <div className='ctn_top'>
              <div className='logo'>
                <img src="https://i.ibb.co/tMdC0Y1/logo.png" alt='' />
              </div>
              <div className='info'>
                { customer?.firstName &&
                  <div>
                    <p>{`${customer?.firstName} ${customer?.lastName}`}</p> 
                    <p>{customerPhone}</p>
                  </div>
                }
                { !customer?.firstName &&
                  <div> 
                    <p>{customerPhone}</p>
                  </div>
                }
                
              </div>
              {showPromo && 
              
              <div className='btn_promotion' onClick={() => setOpenPromo(true)}>
                <div className='ctn_btn_promotion'>
                  <img src={lng == "ar" ? "images/promo_ar.png" : "images/promo.png"}/>
                  <Bounce forever={true} duration={2000}>
                    <div className='ctn_flash_promotion'><img src="images/flash.png"/></div>
                  </Bounce>
                </div> 
              </div>
              
              }
            </div>
            <div className='catalogue_ctn_banners'>
              <div className='ctn_img'> 
                    { collection?.length > 0 ? 
                    <Carousel
                  autoPlay={true} 
                  infiniteLoop={true} 
                  emulateTouch={true} 
                  > 
                    {collection?.map((item,index) => (
                      <div  key={index}>
                          <img src={item.imageUrl} /> 
                      </div>
                      ))
                    }   
                    </Carousel> : 
                    <Carousel
                    autoPlay={true} 
                    emulateTouch={true}
                    showThumbs={false}
                    >
                      <div>
                          <img src={"https://charico-assets.s3.us-east-2.amazonaws.com/banners/0/default.png"} /> 
                      </div> 
                      </Carousel> 
                      }  
              </div>
            </div>
          </div>
          <div className='catalogue_bottom'>
            <div className='ctn_left'>
              <div className='ctn_nav_families'>
                <div className='family_list'>
                {families?.map((item,index) => (
                  <div  name={item?.familyId} className={(index == 0) ? 'family family_selected' : 'family' } onClick={() => clickFamily(item?.familyId )} key={item?.familyId}>
                    <div></div>
                    <div>
                      <img src={item?.imageUrl} alt=''/>
                      <p>{lng == "ar" ? item?.descriptionAr : item?.description}</p> 
                    </div> 
                  </div> 
                  ))} 
                </div>
              </div>
            </div>
            <div className='ctn_right'>
              <div className='ctn_nav_brands'>
                <h4>{selectedFamily}</h4>
                <div className='brand_list'>
                {brands?.map((item,index) => (
                    <div name={item?.brandId} className={(index == 0) ? 'brand brand_selected' : 'brand' } onClick={() => setSelectedBrand(item?.brandId)} key={item?.brandId}>
                      <img src={item?.imageUrl} alt=''/>
                      <span>{item?.description}</span>
                      <div></div>
                    </div> 
                  ))}  
                </div>
              </div>
              <div className='ctn_nav_articles'>
                <div className='ctn_bloc'>
                {blocs?.map((item) => (
                  <Waypoint topOffset={0} bottomOffset={880} onLeave={(currentPosition) => onScrollBloc(item?.brandId,currentPosition,"leave")} onEnter={(currentPosition) => onScrollBloc(item?.brandId,currentPosition,"enter")} key={item?.brandId}>
                    <div className='bloc' id={item?.brandId}>
                    <h4>{item?.description}</h4>
                    <div className={lng == "ar" ? "articles_listes_catalog articles_listes_catalog_ar" :  "articles_listes_catalog"}>
                    {item?.articles?.map((item,index) => (   
                      <Fade  bottom  key={item?.articleId}>
                      <div className={(index == 0) ? 'article' : 'article' }>
                      <div  onClick={() => onClickArticle(item)} className='ctn_img'>
                        <img src={item?.imageListUrl[0]} alt=''/>
                      </div>
                      <div  onClick={() => onClickArticle(item)} className='ctn_desc'>
                        <h5>{lng == "ar" ? item?.commercialDesignationAr : item?.commercialDesignation}</h5>
                      </div>
                      {item?.unitText && 
                      <div className='ctn_info'>
                        <p>{(item?.unitText == "Pièce") ? trans('articleUnt') : trans('articleCaisse', { qte: item?.caseItems })}</p>
                      </div>
                      } 
                      {item?.articlePromotionInfo?.paliers?.length > 0 ? ( 
                            <> 
                              <div className='ctn_bottom_info'>
                                <div className='left'>
                                <div> 
                                {item?.unitText == "CAISSE" ? ( 
                                        <>
                                          <p>{trans('palierCaisse', { qte: item?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                          <p>{item?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                          <p>{`${item?.caseItems} × ${item?.articlePromotionInfo?.paliers[0]?.unitPrice?.toFixed(2)}  ${trans('dh')}`}</p>
                                        </>
                                        ) : (
                                          <>
                                          <p>{trans('palierUnt', { qte: item?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                          <p>{item?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                          <p></p>
                                        </>
                                      )} 
                                      </div>
                                </div>
                                <div className='right'>
                                  <div>
                                {(item?.inPromo && item?.promotionPercent > 0) ? ( 
                                    <> 
                                    {item?.unitText == "CAISSE" ? ( 
                                        <>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                          <p>{`${item?.caseItems} × ${(item?.unitPromotionPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                        </>
                                        ) : (
                                          <>
                                            <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                            <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                            <p></p>
                                          </>
                                      )} 
                                    </>
                                  ) : (
                                    <>
                                      {item?.unitText == "CAISSE" ? ( 
                                        <>
                                          <p className='no_line_through'><span>5</span></p>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{`${item?.caseItems} × ${(item?.unitPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                        </>
                                        ) : (
                                          <>
                                            <p></p>
                                            <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                            <p></p>
                                          </>
                                      )} 
                                    
                                    </>
                                  )} 
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className={lng == "ar" ? "ctn_bottom_info ctn_bottom_info_without_palier ctn_bottom_info_without_palier_ar" :  "ctn_bottom_info ctn_bottom_info_without_palier"}> 
                                <div className='right'>
                                  <div>
                                {(item?.inPromo && item?.promotionPercent > 0)? ( 
                                    <> 
                                    {item?.unitText == "CAISSE" ? ( 
                                        <>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{`${item?.caseItems} × ${(item?.unitPromotionPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                        </>
                                        ) : (
                                          <>
                                            <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                            <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                            <p></p>
                                          </>
                                      )}  
                                    </>
                                  ) : (
                                    <>
                                    {item?.unitText == "CAISSE" ? ( 
                                        <>
                                          <p></p>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{`${item?.caseItems} × ${(item?.unitPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                        </>
                                        ) : (
                                          <>
                                            <p></p>
                                            <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                            <p></p>
                                          </>
                                      )} 
                                    </>
                                  )}  
                                  </div>
                                </div>
                              </div>
                            </>
                          )}  
                          {item?.articlePromotionInfo?.gifts?.length > 0 ? (
                            <>
                              <div className='ctn_gift'>
                                <div>
                                <img src="images/gift_card.png"/>
                                </div>
                                <div>
                                  <p>{trans('giftP1', { qte: item?.articlePromotionInfo?.gifts[0]?.minimum })}</p>
                                  <p>
                                    {
                                      (item?.articlePromotionInfo?.gifts[0]?.article.articleId == item?.articleId)
                                      ? (item?.articlePromotionInfo?.gifts[0]?.giftQuantity > 1 ) ? trans('giftP3', { qte: item?.articlePromotionInfo?.gifts[0]?.giftQuantity }) : trans('giftP2', { qte: item?.articlePromotionInfo?.gifts[0]?.giftQuantity }) 
                                      : trans('giftP4')
                                    } 
                                  </p>
                                </div>
                              </div>
                            </>
                            ) : (
                            <>
                            </>
                            )}
                      {(item?.inPromo && item?.promotionPercent > 0) && 
                      <div className='promo'>
                        <span>-{parseInt(item?.promotionPercent)}%</span>
                      </div>
                      } 
                      {!idArticlesInPanier.includes(item?.articleId) && item?.stockQuantity > 0 &&
                      <div className='added_cart'>
                        <div onClick={() => addArticleToCart(item)} >
                          <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
                        </div>
                      </div>
                      }
                      {idArticlesInPanier.includes(item?.articleId) &&
                      <div className='qte'>
                        <div className='ctn'>
                          <div className='plus' onClick={() => changeQte(item,'plus')}>
                            <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
                          </div>  
                          <input type="text" onClick={() => changeQteNumPad(item,idArticlesInPanierQte.find(obj => { return obj.id === item?.articleId }).qte)} onChange={() => 1} value={idArticlesInPanierQte.find(obj => { return obj.id === item?.articleId }).qte} />
                          <div className='minus' onClick={() => changeQte(item,'minus')}>
                            <img src='https://i.ibb.co/9p0Rtcp/remove.png' alt=''/>
                          </div>
                        </div>
                      </div>
                      }
                      {item?.stockQuantity <= 0 &&
                      <div className='repture'>
                        <div>
                          <span>{trans('repture')}</span>
                        </div>
                      </div>
                      }
                    </div> 
                    </Fade >  
                      ))}   
                    </div>
                  </div>
                  </Waypoint>
                  ))}  
                </div>
              </div>
            </div> 
            <div className='ctn_panier'>
              <div className='total_price'>
                <div onClick={() => onClickPanier()}>
                  <img src='https://i.ibb.co/3zz03jp/shopping-cart.png' alt=''/>
                  <div><span>{orderLines?.length ? orderLines.map(orderLine => (orderLine.isGift) ? 0 : orderLine.quantity).reduce((a, b) => a + b) : 0}</span></div>
                </div>
                <div><span className='total'>{totalPanier.toFixed(2).toLocaleString('fr')} {trans('dh')}</span></div> 
                <div className='clear'></div>
              </div>
              <div className='ctn_btn'>
                <div onClick={() => setOpenCancel(true)} className='btn_order restart_order'>
                    <span>{trans('panier.abandonner')}</span>
                </div>
                <div onClick={() => onClickPanier()} className='btn_order add_order'>
                  <span>{trans('panier.voirMaCommande')}</span>
                </div>
              </div>
            </div>
          </div>
        {!!singleArticle && (
          <PopupSingleArticle
            open={!!singleArticle} 
            article={singleArticle}  
            onClose={() => setSingleArticle(undefined)}
            getTotalPanier={() => getTotalPanier()}
            setChangeQteCount={() => setChangeQteCount()}
            setQteToOrder={() => setQteToOrder()}
            addArticleToCart={(e) => addArticleToCart(e)}
            showMsg={(e) => showMsg(e)}
            changeQteCount={changeQteCount}
            lng={lng}  
          />
        )}
        </div>
        </>
        </StoreContainer>
      </>
    ); 
};
export default Catalogue;
