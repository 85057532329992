import React, {useContext, useEffect,useState} from 'react';
import PropTypes from 'prop-types';  
import { useTranslation } from 'react-i18next'; 
import { Dialog,Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { CancelToken, SLOTS } from 'myAxios'; 
import useAxios, { GET } from 'hooks/useAxios'; 
import { OrderContext } from 'pages/OrderContext';
import moment from 'moment';
const SlotDialog = ({ 
  open,
  onClose,
  onSuccess,  
  idSlot,  
  channelId,  
  setIdSlot,
  desiredDeliveryDate,  
  setDesiredDeliveryDate,
  disableButtons,
}) => {    
  const {setSlotReceipt} = useContext(OrderContext);
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const [lng, setLng] = useState(); 
  const [indexSelected, setIndexSelected] = useState(null);
  const { data: slots, call: getSlots } = useAxios(GET, SLOTS, {
    withProgressBar: true,
  });
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);  
  useEffect(() => { 
    if(open){
      const source = CancelToken.source();    
      getSlots({ params: { 
        channelId: channelId, 
      },source });   
      setIndexSelected(null)
      return source.cancel; 
    }
      
  }, [open]);
  const onClickSlot = (slot,index) => { 
    if(slot){
      let preSlot = ` ${moment(slot.desiredDeliveryDate).format('DD/MM/YYYY')} - De ${slot?.startHour?.value?.hours}:00 à ${slot?.endHour?.value?.hours}:00 `
      setIdSlot(slot.deliverySlotId);
      setDesiredDeliveryDate(slot.desiredDeliveryDate); 
      setIndexSelected(index);
      setSlotReceipt(preSlot);
    }
    
  };
  const getDay = (slot) => {  
    if(slot){ 
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate()+1);
      var desiredDeliveryDate = new Date(slot.desiredDeliveryDate);
      var isToday = (today.toDateString() == desiredDeliveryDate.toDateString());
      var isTomorrow = (tomorrow.toDateString() == desiredDeliveryDate.toDateString());
      if(isToday){
        return trans('slot.today');
      }else if(isTomorrow){
        return trans('slot.tomorrow');
      } else{
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const d = new Date(slot.desiredDeliveryDate);
        let day = weekday[d.getDay()];
        return trans(day);
      } 
    }
    
  };
  useEffect(() => {   
    console.log('idSlot',idSlot);
      console.log('desiredDeliveryDate',desiredDeliveryDate); 
      console.log('slots',slots); 
      console.log('channelId',channelId); 
}, [desiredDeliveryDate,idSlot,slots,channelId]);

  return (
    <Dialog  className={lng == "ar" ? "popup_slots popup_slots_ar" :  "popup_slots"} open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography id="form-dialog-title"> 
        {trans('slot.title')}
      </DialogTitle>
      <DialogContent>
      <div className={"ctn_slots"}>  
      {slots && slots.map((item,index) => ( 
          <div onClick={() => onClickSlot(item,index)} className={indexSelected == index ? "slot active" : "slot"} key={index}>
            <p>{getDay(item)}: {trans('slot.de')} {item?.startHour?.hours}:00 {trans('slot.a')} {item?.endHour?.hours}:00</p> 
            <img src='https://i.ibb.co/tmS6MZJ/check.png'/> 
          </div> 
          ))} 
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!disableButtons}
          onClick={(e) => {
            e.stopPropagation();
            onSuccess();
          }}
          variant="outlined"
          color="primary"
        >
          {trans('confirmer')}
        </Button> 
      </DialogActions> 
    </Dialog>
  );
}; 
SlotDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setIdSlot: PropTypes.func.isRequired, 
  setDesiredDeliveryDate: PropTypes.func.isRequired, 
  disableButtons: PropTypes.bool, 
  idSlot: PropTypes.number, 
  channelId: PropTypes.number, 
  desiredDeliveryDate: PropTypes.string, 
};

export default SlotDialog;
