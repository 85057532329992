import React, { useState ,useRef } from 'react';
import { trans } from 'itranslator';
import PageTitle from 'utils/components/PageTitle';
import { Card } from '@material-ui/core';
import useStyles from 'pages/styles';  
import OtpInput from 'react-otp-input';
import Keyboard from "react-simple-keyboard";  
import "react-simple-keyboard/build/css/index.css";

const Home = () => {
  const classes = useStyles(); 
  const [otp, setOtp] = useState(null);  
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  function handleChange(otp) {
    setOtp(otp);
  }
  const onChange = input => {
    setOtp(input); 
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = button => { 
    if (button === "{shift}" || button === "{lock}") handleShift();
  }; 
  return (
    <>
      <PageTitle>{trans('home')}</PageTitle>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        separator={<span>-</span>}
      />
      <Card className={classes.formContainer}>
          {otp}
      </Card>
      <Keyboard
        keyboardRef={r => (keyboard.current = r)} 
        layoutName={layout}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <Card className={classes.formContainer}>
        {trans('welcomeBackHome')} 
      </Card>
    </>
  );
};
export default Home;
