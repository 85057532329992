import React, { useState , useEffect ,useContext } from 'react';
import { Button, Typography} from '@material-ui/core';  
import { useParams } from 'react-router-dom';    
import OtpInput from 'react-otp-input';
import useStyles from 'pages/styles';  
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signIn } from 'actions/security';
import { Store as SecurityStore } from 'stores/securityStore'; 
import { useSnackbar } from 'notistack'; 
import _ from 'lodash';
import NumPad from 'utils/components/NumPad';
import clsx from 'clsx';
import { isOtp } from 'helpers/validators';
import { OrderContext } from 'pages/OrderContext';
import ButtonBack from 'utils/components/buttonBack'; 
import Fade from 'react-reveal/Fade';
const SignInOtp = () => {
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode} = useContext(OrderContext); 
  useEffect(() => {  
    setOrderLines();
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
  }, []);
  const { phone } = useParams();    
  const [otp, setOtp] = useState(null);   
  const classes = useStyles(); 
  const history = useHistory(); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const {
    state: { connected },
  } = useContext(SecurityStore);

  useEffect(() => {
    if (connected && history) {
      history.push('/borne');
    }
  }, [history, connected]);
  const {
    dispatch,
    state: {  signIngIn ,signInErrorMessage },
  } = useContext(SecurityStore);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!signInErrorMessage || !enqueueSnackbar) {
      return;
    }

    if (_.isString(signInErrorMessage)) {
      enqueueSnackbar(signInErrorMessage, { variant: 'error' });
    } else if (_.isObject(signInErrorMessage)) {
      Object.values(signInErrorMessage).forEach((e) => enqueueSnackbar(e, { variant: 'error' }));
    }
  }, [enqueueSnackbar, signInErrorMessage]);
  function handleChange(otp) {
    setOtp(otp);
  }
  //===========================
  const [enableOtp, setEnableOtp] = useState(false); 
  useEffect(() => { 
    setEnableOtp(isOtp(otp));  
  }, [otp]);
  //===========================
  const onSubmit = (e) => {
    e.preventDefault();
 
    signIn({
      phoneNumber: phone,
      code: otp,
      skipSendCode: false,
    })(dispatch); 
    
  };
  return (
    <div className={"screen_background screen_background_otp screen_background_otp_responsable"}>
        <ButtonBack   
                  link={"/pre/order/insertPhone"} 
                  icon={true}
                /> 
        <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
        <Typography component="h1" variant="h5">
          {trans('authentication.otp')+' '+phone}
        </Typography>
        <div className={"ctn_login"}>  
          <div className={clsx(classes.form, 'login_phone')} noValidate>
            <div className="ctn_otp">
              <OtpInput
                value={otp}
                className="otp" 
                onChange={handleChange}
                numInputs={6}
                isInputNum={true}
                separator={<span>-</span>}
              /> 
            </div>
            <div className='login_numpad'>  
            <NumPad
              value={otp}
              setValue={setOtp} 
              type='otp'
            /> 
            </div> 
            <Button 
              type="button" 
              disabled={!enableOtp || signIngIn}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onSubmit}
            >
              {trans('suivantFr')}
            </Button>
          </div> 
          </div>
      </div> 
  );
};

export default SignInOtp;
