import React, {useContext,useEffect, useState} from 'react'; 
import { Typography} from '@material-ui/core';    
import { useTranslation } from 'react-i18next'; 
import StoreContainer from 'pages/StoreContainer';
import ButtonBack from 'utils/components/buttonBack';
import { useHistory } from 'react-router-dom'; 
import { OrderContext } from 'pages/OrderContext'; 
import Tada from 'react-reveal/Tada';
import Fade from 'react-reveal/Fade';
const DeliveryMethods = () => {    
  const {setOrderLines,setOrderId,setDeliveryMode,customer} = useContext(OrderContext); 
  useEffect(() => {  
    setOrderLines(); 
    setOrderId(null);
    setDeliveryMode(null);
  }, []);
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n; 
  const history = useHistory();
  const [lng, setLng] = useState();  
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);    
  const getMethods = (method) => {  
    history.push('/catalogue'); 
    setDeliveryMode(method);
  };
  return (
    <>
      <StoreContainer> 
        <div className={"screen_background screen_background_delivery"}>
            <ButtonBack   
                  link={"/startPage"} 
                  icon={true}
                /> 
        <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
            <Tada>
              <Typography className={lng == "ar" ? "title_ar" : ""} component="h2" variant="h5">
                <p>
                  <span>{trans('deliveryMode.p1')}</span>
                  { customer?.firstName &&
                  <span>{`${customer?.firstName} ${customer?.lastName}`}</span>
                }
                </p> 
              </Typography>
            </Tada> 
            <Tada>
            <Typography component="h1" variant="h5">
            {trans('deliveryMode.p2')}
            </Typography> 
            </Tada>
            <div className="ctn_methods">
              <div className='ctn'> 
              <Fade left>
                <div onClick={() =>getMethods("To Go")}>
                  <img src='https://i.ibb.co/Qm1Lcqx/ToGo.png' alt=''/> 
                  <h3>{trans('deliveryMode.mode11')}</h3>
                  <h4>{trans('deliveryMode.mode12')}</h4>
                </div>
                </Fade>
                {customer?.latitude > 0 && (
                <Fade right>
                <div onClick={() =>getMethods("To Deliver")}>
                  <img src='https://i.ibb.co/2cG2mKJ/delivery.png' alt=''/> 
                  <h3>{trans('deliveryMode.mode21')}</h3>
                  <h4>{trans('deliveryMode.mode22')}</h4>
                </div>
                </Fade>
                )}
              </div>
            </div>
          </div>  
        </StoreContainer>
    </>
  );
};
export default DeliveryMethods;
