export const translationFr = { 
  start: {
    p1: 'مرحبًا بك في شاري',
    p2: 'داخل شاري خارج رابح',
    btn: 'إبدء هنا',
  },  
  authentication: { 
    p1: 'Authentification magasin',
    otp: 'Entrez le code reçu par SMS au', 
  },
  authenticationCustomer: { 
    p1: 'Entrez votre numéro de téléphone',
    otp: 'Entrez le code reçu par SMS au', 
    error: 'le code que vous avez entré est incorrecte', 
  }, 
  canal: { 
    title: 'Sélectionne le type d’activité commerciale',
    cgu1: 'En validant ton inscription, tu confirmes avoir lu et accepté sans réserve',
    cgu2 : 'nos conditions générales d’utilisation',
  }, 
  slot: { 
    title: 'A quelle heure voulez-vous que nous livrions votre commande ? ',
    today: 'Aujourd’hui ',
    tomorrow: 'Demain',
    de  : 'De',
    a : 'à',
  },
  deliveryMode: {
    p1: 'Bonjour',
    p2: 'Merci de choisir votre mode de livraison',
    mode11: 'Retrait en magasin',
    mode12: 'Récupérez votre marchandise après confirmation de la commande',
    mode21 : 'Livraison chez vous',
    mode22 : 'Votre marchandise sera livrée dans votre magasin',
  }, 
  popupMsg: {
    addArticle: 'Article ajouté au panier',
    minOrder: 'le minimum à commander est {{qte}}',
    deleteArticle: 'Article supprimé du panier',
    qte: 'Quantité demandée n’est pas disponible',
    abandonner: 'Etes-vous sûr de vouloir abandonner votre commande ? ',
    horsZone : 'Votre emplacement est hors zone de livraison, souhaitez vous retirer votre commande sur place ?',
    oui : 'Oui',
    non : 'Non',
    NonMerci : 'Non merci',
    OuiBienSur : 'Oui bien sur', 
  },
  panier: {
    total: 'Total',
    sousTotal: 'Sous-total',
    taxe : 'Taxe',
    voirMaCommande  : 'Voir ma commande',
    viderPanier : 'vider le panier',
    ajouterPanier : 'Ajouter au panier',
    abandonner : 'Abandonner',
    mesCommandes : 'Mes commandes',
    continuerAchats : 'Continuer vos achats',
    validerCommande  : 'Valider ma commande ',
    succesMessageAdd : 'Votre commande a été effectuée avec succès',
    monPanier : 'Mon panier',
    reference : 'référence',
    references : 'références',
    aLivrer  : 'À livrer',
    aPrendre  : 'À prendre',
    gratuit  : 'Gratuit',
    btnDeleteProduct  : 'Supprimer l’article ',
    annuler  : 'Annuler',
    deleteProduct  : 'Etes-vous sur de supprimer l’article {{designation}} du panier ?',
    panierMsg  : 'Commande minimum {{minimum}} DH',
    coutLogistique   : 'Coût logistique',
    remiseLogistique   : 'Remise logistique',
    continuerVosAchats   : 'Continuer vos achats',
    totalHt   : 'Total HT',
    totalNetHt   : 'Total net HT',
    totalTva   : 'Total TVA', 
    tva   : 'TVA', 
    totalTtc   : 'Total Ttc',  
    timbre  : 'Timbre',
  },
  confirmOrder: { 
    merci : 'Merci',
    p1 : 'Votre commande est bien confirmée',
    p2 : 'Merci de choisir votre mode de livraison',
    p3 : 'Voulez-vous passer une nouvelle commande ?',
    NonMerci : 'Non merci',
    OuiBienSur : 'Oui bien sur', 
  },
  cgu: { 
    p1: 'CHARI s’engage à protéger vos données personnelles sur le web en adhérant au Dahir n° 1-09-15 du 22 safar 1430 (18 février 2009) portant promulgation de la loi n° 09-08 relative à la protection des personnes physiques à l’égard du traitement des données à caractère personnel', 
    p2: 'Toute personne qui communique ses données personnelles, a le droit de :', 
    p3: '- d’accès : consulter vos données personnelles', 
    p4: '- de rectification : compléter et/ou corriger vos données personnelles', 
    p5: '- d’oubli : supprimer vos données selon certains critères', 
    p6: '- de limitation de traitement : restreindre le traitement de vos données pendant une période donnée', 
    p7: '- de portabilité des données : transférer vos données facilement entre les systèmes', 
    p8: '- d’opposition : s’opposer au traitement de vos données à des fins marketing', 
    p9: 'Vous pouvez en connaître davantage sur vos droits d’utilisation des données en lisant la page de La Commission Nationale de contrôle de la protection des Données à caractère Personnel (CNDP)  https://www.cndp.ma/images/lois/Loi-09-08-Fr.pdf', 
    p10: 'Vos données personnelles sont importantes pour CHARI que vous soyez nouveau client ou utilisateur des produits et services ou un client de longue date, vos données personnelles seront gérées dans le cadre de notre politique de protection des données personnelles et des cookies informatiques. Si vous avez des questions n’hésitez pas à nous contacter.', 
    
  },
  free: {
    one: 'Une unité',
    many: '{{qte}} unités', 
    free: 'Gratuit', 
  },
  suivant : 'Suivant',
  suivantFr : 'Suivant',
  retour : 'Abandonner',
  arabe : 'العربية',
  francais : 'Français',
  confirmer  : 'Confirmer ',
  choose_terminal_title  : 'C’est quelle borne ?',
  contact_service  : 'veuillez contacter le service clientèle',
  unit  : 'unit',
  Pièce  : 'Unité',
  PièceFiche  : 'Vendu au unité',
  Pièces  : 'unités',
  U  : 'U',
  CAISSE  : 'Caisse',
  CAISSEFiche  : 'Vendu au caisse',
  dh  : 'dh',
  dhUnite  : 'dh/unité',
  droitTimbre  : 'Dont droit de timbre',
  code  : 'Code',
  supprimerArticle  : 'Supprimer l’article',
  monday  : 'Lundi',
  repture  : 'Non disponible',
  promotions  : 'Promotions',
  errQte  : 'Quantité demandée n’est pas disponible',
  retourr  : 'Retour',
  orderLineErrQte: 'Seulement {{qte}} disponible',
  Pce  : 'Pce',
  Cse  : 'Cse',
  cmdN: 'Commande N° {{order}}',
  retrait: 'Retrait',
  livraison: 'Livraison',
  chari: 'Chari', 
  address: 'Zone industrielle Ouled SALAH ,Bouskoura 27182 CASABLANCA', 
  receipt: { 
    article: 'Article ({{nbarticle}})', 
    puNet: 'PuNet', 
    qte: 'Qté', 
    ute: 'Uté', 
    total: 'Total', 
    thanks: 'Merci pour votre visite', 
  },
  articleCaisse  : 'Vendu au carton : {{qte}} unités',
  palierCaisse  : 'Dès {{qte}} cartons',
  palierUnt  : 'Dès {{qte}} unités',
  articleUnt  : 'Vendu a l’unité',
  giftP1  : '{{qte}} achetés',
  giftP2  : '= {{qte}} gratuit',
  giftP3  : '= {{qte}} gratuits',
  giftP4  : '= cadeau',
  giftSingleArticleP2  : '{{qte}} gratuit',
  giftSingleArticleP3  : '{{qte}} gratuits',
  Sunday  : 'Dimanche',
  Monday  : 'Lundi',
  Tuesday  : 'Mardi',
  Wednesday  : 'Mercredi',
  Thursday  : 'Jeudi',
  Friday  : 'Vendredi',
  Saturday  : 'Samedi',
  minToOrder  : 'le minimum à commander est {{qte}}',
};


export default translationFr;
