import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types'; 
import storage from 'helpers/storage';
 
const initialState = {
  signIngIn: false, 
  connected:storage.isLoggedIn(),
  signInErrorMessage: '',
};

export const Store = createContext({ state: initialState });

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT = 'SIGN_OUT';

const reducer = (state, { event, payload }) => {
  switch (event) {
    case SIGN_IN_REQUEST:
      return {
        signIngIn: true, 
        connected:false,
        signInErrorMessage: '',
      };
    case SIGN_IN_SUCCESS:
      return {
        signIngIn: false, 
        connected:true,
        signInErrorMessage: '',
      };
    case SIGN_IN_FAILURE:
      return { 
        signIngIn: false,
        connected:false,
        signInErrorMessage: payload,
      };
    case SIGN_OUT:
      return { 
        signIngIn: false,
        connected:false,
        signInErrorMessage: '',
      };
    default:
      return state;
  }
};

export const SecurityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
   return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>;
};

SecurityProvider.propTypes = {
  children: PropTypes.element,
};
