import React, { useEffect,useState} from 'react';
import PropTypes from 'prop-types';  
import { useTranslation } from 'react-i18next'; 
import { Dialog, DialogContent } from '@material-ui/core'; 
import Fade  from 'react-reveal/Fade';
const PromotionDialog = ({ 
  openPromo,
  onClosePromo,
  onClickArticle,
  idArticlesInPanier,
  addArticleToCart,
  changeQteNumPad,
  idArticlesInPanierQte,
  changeQte,
  listArticlesPromo,   
}) => {     
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const [lng, setLng] = useState();  
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]);  
  return (
    <Dialog  className={"store_container_promotion"} open={openPromo} onClose={onClosePromo} aria-labelledby="form-dialog-title">
       <DialogContent>
        <div className='close' onClick={onClosePromo}>
          <img src="images/close.png" alt='' />
        </div>
        <div className='promotion_top'>
          <img src={lng == "ar" ? "images/promotion_top_ar.png" : "images/promotion_top_fr.png"}/>
        </div> 
       <div className={lng == "ar" ? "articles_listes_catalog articles_listes_catalog_ar" :  "articles_listes_catalog"}>
                  {listArticlesPromo?.map((item,index) => (   
                    <Fade  bottom   key={item?.articleId}>
                    <div className={(index == 0) ? 'article' : 'article' }>
                    <div  onClick={() => onClickArticle(item)} className='ctn_img'>
                      <img src={item?.imageListUrl[0]} alt=''/>
                    </div>
                    <div  onClick={() => onClickArticle(item)} className='ctn_desc'>
                      <h5>{lng == "ar" ? item?.commercialDesignationAr : item?.commercialDesignation}</h5>
                    </div>
                    {item?.unitText && 
                    <div className='ctn_info'>
                      <p>{(item?.unitText == "Pièce") ? trans('articleUnt') : trans('articleCaisse', { qte: item?.caseItems })}</p>
                    </div>
                    } 
                    {item?.articlePromotionInfo?.paliers?.length > 0 ? ( 
                          <> 
                            <div className='ctn_bottom_info'>
                              <div className='left'>
                              <div>
                              {item?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p>{trans('palierCaisse', { qte: item?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                        <p>{item?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                        <p>{`${item?.caseItems} × ${item?.articlePromotionInfo?.paliers[0]?.unitPrice?.toFixed(2)}  ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                        <p>{trans('palierUnt', { qte: item?.articlePromotionInfo?.paliers[0]?.minimum })}</p>
                                        <p>{item?.articlePromotionInfo?.paliers[0]?.casePrice?.toFixed(2)} {trans('dh')}</p> 
                                        <p></p>
                                      </>
                                    )} 
                                </div>
                              </div>
                              <div className='right'>
                              <div>
                              {(item?.inPromo && item?.promotionPercent > 0)? ( 
                                  <> 
                                  {item?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                        <p>{`${item?.caseItems} × ${(item?.unitPromotionPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p> 
                                          <p></p>
                                        </>
                                    )} 
                                  </>
                                ) : (
                                  <>
                                    {item?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p className='no_line_through'><span>5</span></p>
                                        <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{`${item?.caseItems} × ${(item?.unitPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p></p>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p></p>
                                        </>
                                    )} 
                                  
                                  </>
                                )} 
                              </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={lng == "ar" ? "ctn_bottom_info ctn_bottom_info_without_palier ctn_bottom_info_without_palier_ar" :  "ctn_bottom_info ctn_bottom_info_without_palier"}> 
                              <div className='right'>
                                <div>
                              {(item?.inPromo && item?.promotionPercent > 0) ? ( 
                                  <> 
                                  {item?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{`${item?.caseItems} × ${(item?.unitPromotionPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p>{item?.unitPromotionPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p></p>
                                        </>
                                    )}  
                                  </>
                                ) : (
                                  <>
                                  {item?.unitText == "CAISSE" ? ( 
                                      <>
                                        <p></p>
                                        <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                        <p>{`${item?.caseItems} × ${(item?.unitPriceTTC / item?.caseItems).toFixed(2)} ${trans('dh')}`}</p>
                                      </>
                                      ) : (
                                        <>
                                          <p></p>
                                          <p>{item?.unitPriceTTC?.toFixed(2)} {trans('dh')}</p>
                                          <p></p>
                                        </>
                                    )} 
                                  </>
                                )}  
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {item?.articlePromotionInfo?.gifts?.length > 0 ? (
                          <>
                            <div className='ctn_gift'>
                              <div>
                              <img src="images/gift_card.png"/>
                              </div>
                              <div>
                                <p>{trans('giftP1', { qte: item?.articlePromotionInfo?.gifts[0]?.minimum })}</p>
                                <p>
                                  {
                                    (item?.articlePromotionInfo?.gifts[0]?.article.articleId == item?.articleId)
                                    ? (item?.articlePromotionInfo?.gifts[0]?.giftQuantity > 1 ) ? trans('giftP3', { qte: item?.articlePromotionInfo?.gifts[0]?.giftQuantity }) : trans('giftP2', { qte: item?.articlePromotionInfo?.gifts[0]?.giftQuantity }) 
                                    : trans('giftP4')
                                  } 
                                </p>
                              </div>
                            </div>
                          </>
                           ) : (
                           <>
                           </>
                           )}  
                    {(item?.inPromo && item?.promotionPercent > 0) &&
                    <div className='promo'>
                      <span>-{parseInt(item?.promotionPercent)}%</span>
                    </div>
                    } 
                    {!idArticlesInPanier.includes(item?.articleId) && item?.stockQuantity > 0 &&
                    <div className='added_cart'>
                      <div onClick={() => addArticleToCart(item)} >
                        <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
                      </div>
                    </div>
                    }
                    {idArticlesInPanier.includes(item?.articleId) &&
                    <div className='qte'>
                      <div className='ctn'>
                        <div className='plus' onClick={() => changeQte(item,'plus')}>
                          <img src='https://i.ibb.co/cbCDXG3/add.png' alt=''/>
                        </div>  
                        <input type="text" onClick={() => changeQteNumPad(item,idArticlesInPanierQte.find(obj => { return obj.id === item?.articleId }).qte)} onChange={() => 1} value={idArticlesInPanierQte.find(obj => { return obj.id === item?.articleId }).qte} />
                        <div className='minus' onClick={() => changeQte(item,'minus')}>
                          <img src='https://i.ibb.co/9p0Rtcp/remove.png' alt=''/>
                        </div>
                      </div>
                    </div>
                    }
                    {item?.stockQuantity <= 0 &&
                    <div className='repture'>
                      <div>
                        <span>{trans('repture')}</span>
                      </div>
                    </div>
                    }
                  </div> 
                  </Fade >  
                    ))}   
                  </div>
      </DialogContent> 
    </Dialog>
  );
}; 
PromotionDialog.propTypes = {
  openPromo: PropTypes.bool,
  onClosePromo: PropTypes.func.isRequired,
  onClickArticle: PropTypes.func.isRequired,
  changeQteNumPad: PropTypes.func.isRequired,
  addArticleToCart: PropTypes.func.isRequired,
  changeQte: PropTypes.func.isRequired,
  listArticlesPromo: PropTypes.any, 
  idArticlesInPanierQte: PropTypes.any, 
  idArticlesInPanier: PropTypes.any, 
};

export default PromotionDialog;
