import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { HashRouter, Route, Switch,Redirect } from 'react-router-dom';   
import { 
  SIGN_IN_PHONE_PAGE,     
  SIGN_IN_OTP_PAGE,     
  HOME,   
  STARTPAGE,   
  INSERTPHONE,   
  DELIVERYMETHODS,   
  NEWCUSTOMER,   
  PAYMENTMETHODS,   
  PANIER,   
  CATALOGUE,   
  CUSTOMER_OTP_PAGE,   
  CUSTOMER_CHANNEL_PAGE,   
  CONFIRMED_ORDER_PAGE,
  BORNE_PAGE,   
} from 'pages/routes';   
import TopLinearProgress from 'utils/components/TopLinearProgress';  
import Home from './Home';   
import SignInPhone from './SignIn/signInPhone';
import SignInOtp from './SignIn/signInOtp';  
import StartPage from './StartPage/startPage';
import InsertPhone from './PreOrder/insertPhone';
import NewCustomer from './PreOrder/newCustomer';
import DeliveryMethods from './PreOrder/deliveryMethods';
import PaymentMethods from './PreOrder/paymentMethods';
import Panier from './Panier/Panier'; 
import SignInCustomerOtp from './PreOrder/signInCustomerOtp'; 
import SignInCustomerCanal from './PreOrder/customerCanal';
import Catalogue from './Catalogue/catalogue';
import { OrderContext } from './OrderContext'; 
import ConfirmedOrder from './Panier/confirmedOrder';
import NumBorne from './SignIn/numBorne';
//const NEW_KEY = 'new';

const App = () => {
  const [orderLines, setOrderLines] = useState();
  const [channel, setChannel] = useState();
  const [channels, setChannels] = useState();
  const [customer, setCustomer] = useState(null);  
  const [customerPhone, setCustomerPhone] = useState(null);  
  const [orderId, setOrderId] = useState(null); 
  const [counter, setCounter] = useState(null); 
  const [orderConfirmedAt, setOrderConfirmedAt] = useState(null); 
  const [deliveryMode, setDeliveryMode] = useState(null);   
  const [footerOrder, setFooterOrder] = useState(null);  
  const [slotReceipt, setSlotReceipt] = useState(null); 
  const [showPopup, setShowPopup] = useState(true); 
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#fb7f08' },
      secondary: { main: '#064fb5' },
    },
    overrides: {
      MuiInputBase: {
        input: {
          color: '#666666',
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TopLinearProgress />
      <OrderContext.Provider value={{deliveryMode,setDeliveryMode,orderLines,setOrderLines, orderId, setOrderId, customer, setCustomer,channels, setChannels,channel, setChannel,footerOrder, setFooterOrder,counter, setCounter,orderConfirmedAt, setOrderConfirmedAt ,customerPhone, setCustomerPhone,slotReceipt, setSlotReceipt,showPopup, setShowPopup}}>
      <HashRouter>
        <Switch>
          <Route exact path={SIGN_IN_PHONE_PAGE} component={SignInPhone} />
          <Route exact path={SIGN_IN_OTP_PAGE} component={SignInOtp} />    
          <Route exact path={STARTPAGE} component={StartPage} />  
          <Route exact path={INSERTPHONE} component={InsertPhone} />  
          <Route exact path={DELIVERYMETHODS} component={DeliveryMethods} />  
          <Route exact path={NEWCUSTOMER} component={NewCustomer} />  
          <Route exact path={PAYMENTMETHODS} component={PaymentMethods} />  
          <Route exact path={PANIER} component={Panier} />  
          <Route exact path={CATALOGUE} component={Catalogue} />  
          <Route exact path={CUSTOMER_OTP_PAGE} component={SignInCustomerOtp} />  
          <Route exact path={CUSTOMER_CHANNEL_PAGE} component={SignInCustomerCanal} />  
          <Route exact path={BORNE_PAGE} component={NumBorne} />  
          <Route exact path={CONFIRMED_ORDER_PAGE} component={ConfirmedOrder} />  
          <Route exact path={HOME} component={Home} />   
          <Route path='*' element={<Redirect to="/" />} /> 
        </Switch>
      </HashRouter>
      </OrderContext.Provider>
    </ThemeProvider>
  );
};
export default App;
