import React, { useState ,useEffect ,useContext } from 'react';
import { Button, Typography} from '@material-ui/core';  
import { useParams } from 'react-router-dom';      
import { useTranslation } from 'react-i18next'; 
import { useHistory } from 'react-router-dom';  
import { OrderContext } from 'pages/OrderContext';  
import ButtonBack from 'utils/components/buttonBack';
import { CUSTOMER_OTP_PAGE } from 'pages/routes';
import Checkbox from '@material-ui/core/Checkbox'; 
import Fade from 'react-reveal/Fade';
import CguDialog from 'pages/CguDialog';
const CustomerCanal = () => {  
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode,channels,setChannel} = useContext(OrderContext); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n; 
  const { phone } = useParams();     
  const history = useHistory();   
  const [canal, setCanal] = useState(0);   
  const [enableCanal, setEnableCanal] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [lng, setLng] = useState();  
  const [open, setOpen] = useState(false);
  useEffect(() => {   
    if(i18n.language)
    setLng(i18n.language);
  }, [i18n.language]); 
  useEffect(() => {  
    setOrderLines();
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
  }, []);   
  const choixCanal = (id) => {  
    setCanal(id);
    setEnableCanal(true);
  }; 
  const onClick = (e) => {
    e.preventDefault();  
    setChannel(canal);
    history.push(CUSTOMER_OTP_PAGE.replace(':phone', phone));  
  }; 
  const clickCgu = (e) => {
    e.preventDefault();    
    setOpen(true);
  }; 
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const onClose = () => { 
    setOpen(false);  
         
  };
  return (
    <div className={"screen_background screen_background_channel"}>
      
    <CguDialog  
          open={open}  
          onClose={() => onClose()} 
        > 
        </CguDialog>
      <ButtonBack   
              link={"/startPage"} 
            />
           <Fade top>
            <div className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
        <Typography component="h1" variant="h5">
          {trans('canal.title')}
        </Typography>
        <div className={"ctn_channels"}>   
        {channels?.map((item) => (
          <div className={(canal == item?.channelId ) ? "channel active" : "channel"} onClick={() => choixCanal(item?.channelId)} key={item?.channelId}>
            <p>{lng == "ar" ? item?.nameAr : item?.nameFr}</p> 
            <img src='https://i.ibb.co/tmS6MZJ/check.png'/> 
          </div>
          ))}
        </div>
        <div className={lng == "ar" ? "ctn_cgu ctn_cgu_ar" : "ctn_cgu"}>
            <div>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div>
              <p>{trans('canal.cgu1')} <a onClick={(e) => clickCgu(e)} href='#'>{trans('canal.cgu2')}</a></p>
            </div>
        </div>
        <Button 
              type="button"
              disabled={!(enableCanal && checked)}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onClick}
            >
              {trans('suivant')}
            </Button>
      </div> 
  );
};

export default CustomerCanal;
