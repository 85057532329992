import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import useStyles from '../styles'; 
import { useHistory } from 'react-router-dom';
import { SIGN_IN_PHONE_PAGE } from 'pages/routes';
import { Store as SecurityStore } from 'stores/securityStore'; 

const StoreContainer = ({ children }) => {
  const classes = useStyles();  
  const history = useHistory();
  const {
    state: { connected },
  } = useContext(SecurityStore);

  useEffect(() => {
    if (!connected && history) {
      history.push(SIGN_IN_PHONE_PAGE);
    }  
  }, [history, connected]);

  return (
    connected ? ( 
        <div className={classes.root}>
          {children}
        </div> 
    ) : <React.Fragment /> 
  );
};

StoreContainer.propTypes = {
  children: PropTypes.element,
};

export default StoreContainer;
