export const customerStatus={
    EN_ATTENTE: 1,
    VALIDE: 2,
    ACTIF: 3,
    DESACTIVE: 4,
    INJOIGNABLE: 5,
    OCCUPE: 6,
    A_ACTIVER_PLUS_TARD: 7,
    HORS_CIBLE: 8,
    EN_ATTENTE_INFORMATION: 9 
}; 
export const orderStatus={
    ENREGISTRE: 1,
    CONFIRME: 2,
    DISPATCHE: 3,
    FACTURE: 4,
    LIVRE: 5,
    RETOURNE: 6,
    ANNULE: 7,
    VALIDE: 8
};     
