import React, {useContext,useEffect} from 'react';
import { useTranslation } from 'react-i18next'; 
import StoreContainer from 'pages/StoreContainer'; 
import { useHistory } from 'react-router'; 
import { OrderContext } from 'pages/OrderContext';   
import useAxios, { GET } from 'hooks/useAxios';    
import { CancelToken, CHANNELS } from 'myAxios';    
const StartPage = () => {   
  //const responsiblePhone = storage.getResponsiblePhone(); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode,channels, setChannels,setSlotReceipt,setShowPopup} = useContext(OrderContext);
  const { data: channelsData, call: getChannels } = useAxios(GET, CHANNELS, {
    withProgressBar: true,
  });  
  useEffect(() => {   
    setOrderLines();
    setSlotReceipt(null);
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
    setShowPopup(true);
    i18n.changeLanguage("ar");
    /*******************/
    if(!channels){
      const source = CancelToken.source();  
      getChannels({ source });  
      return source.cancel;
    }  
  }, []); 
  useEffect(() => {
    if(channelsData)
    setChannels(channelsData);
  }, [channelsData]);   
  
  const history = useHistory();     
     const onClick = () => {   
          history.push('/pre/order/insertPhone');  
    }; 
  return (
    <>
    <StoreContainer> 
    <div className={"screen_background screen_background_home"}>
      <div className={"img_home"}> 
      <div className={"ctn_home"}>
        <h2>{trans('start.p2')}</h2> 
        <div className='btn' onClick={onClick}>
          <span>{trans('start.btn')}</span> 
        </div>  
        </div>
      </div>   
        </div>
        </StoreContainer>
    </>
  );
};
export default StartPage;