import React, { useEffect, useState, useContext, useCallback  } from 'react';
import useStyles from '../styles'; 
import { Button, Input, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import StoreContainer from 'pages/StoreContainer';
import clsx from 'clsx'; 
import NumPad from 'utils/components/NumPad';
import { useTranslation } from 'react-i18next';
import { isPhone } from 'helpers/validators'; 
import ButtonBack from 'utils/components/buttonBack';
import { CUSTOMER_CHANNEL_PAGE } from 'pages/routes'; 
import { OrderContext } from 'pages/OrderContext';
import { CancelToken, CUSTOMER_SIGN_IN_PATH } from 'myAxios';
import useAxios, { POST } from 'hooks/useAxios';
import Jump from 'react-reveal/Jump'; 
import Fade from 'react-reveal/Fade';
import ConfirmationDialog from 'utils/components/ConfirmationDialog'; 
import { useLongPress } from 'use-long-press';
import { signOut } from 'actions/security';
import { Store } from 'stores/securityStore'; 
const InsertPhone = () => {    
  const {setOrderLines,setCustomer,setOrderId,setDeliveryMode,setCustomerPhone} = useContext(OrderContext); 
  useEffect(() => {  
    setCustomerPhone();
    setOrderLines();
    setCustomer(null);
    setOrderId(null);
    setDeliveryMode(null);
  }, []);
  const { dispatch } = useContext(Store);
  const _signOut = () => signOut()(dispatch); 
  const { i18n } = useTranslation(); 
  const {t: trans} = i18n;
  const history = useHistory(); 
  const classes = useStyles();
  const [phone, setPhone] = useState('');   
  const [enablePhone, setEnablePhone] = useState(false); 
  const [open, setOpen] = useState(false); 
  const { call } = useAxios(POST, CUSTOMER_SIGN_IN_PATH, {
    withProgressBar: true,
  });
  useEffect(() => { 
    setEnablePhone(isPhone(phone));  
  }, [phone]); 
  const changeLanguage = (lang) => {  
    i18n.changeLanguage(lang);
  };   
  const cancel = () => { 
    history.push("/startPage"); 
  };
  const onClick = (e) => {
    e.preventDefault(); 
    setCustomerPhone(phone);
    const source = CancelToken.source(); 
    const body = {}; 
    body['phoneNumber'] = phone; 
    body['code'] = ""; 
    body['skipSendCode'] = false;  
      call({ body, source }).then((response) => {  
        if(response?.status === 202){
          history.push(CUSTOMER_CHANNEL_PAGE.replace(':phone', phone)); 
        }
        if(!response){ 
          setOpen(true);
        }
        if(response?.status === 200){
          let apiCustomer = response.data.data;
          let customer = {};
          customer.customerId = apiCustomer.customerId;
          customer.contactName = apiCustomer.contactName;
          customer.firstName = apiCustomer?.user?.firstName;
          customer.lastName = apiCustomer?.user?.lastName; 
          customer.code = apiCustomer.code; 
          customer.channelId = apiCustomer.channelId; 
          customer.phoneNumber = apiCustomer.phoneNumber; 
          customer.latitude = apiCustomer.addresses[0]?.latitude; 
          customer.longitude = apiCustomer.addresses[0]?.longitude; 
          setCustomer(customer);  
          history.push('/pre/order/deliveryMethods');  
         
        } 
      }); 
  };
  const callback = useCallback(() => { 
    _signOut();
  }, []);
  const bind = useLongPress(callback , { 
    threshold: 5000, 
  });
  return (
    <>
    <StoreContainer> 
    <>
    <ConfirmationDialog 
          textLeft={trans('popupMsg.oui')}
          textRight={trans('popupMsg.non')}
          open={open}
          onClose={() => setOpen(false)}
          onSuccess={() => cancel()}
        >
          {trans('contact_service')}
        </ConfirmationDialog>
    <div className={"screen_background screen_background_phone_customer"}>
    <ButtonBack   
              link={"/startPage"} 
              icon={true}
            /> 
            <Fade top>
            <div {...bind()} className='logo'>
              <img src='https://i.ibb.co/tMdC0Y1/logo.png'/>
            </div>
        </Fade>
        <div className={"ctn_btn_lang"}> 
        <div>
        <Button 
              type="button"
              fullWidth 
              variant="outlined"
              color="primary"
              className={(i18n.language == "fr") ? "active" : ""}  
              onClick={()=>changeLanguage("fr")}
            >
              {trans('francais')}
            </Button>
            <Button 
              type="button"
              fullWidth 
              variant="outlined"
              color="primary"
              className={(i18n.language == "ar") ? "active" : ""} 
              onClick={()=>changeLanguage("ar")}
            >
              {trans('arabe')}
            </Button>
        </div>
        
        </div> 
            <Jump> 
        <Typography component="h1" variant="h5">
          {trans('authenticationCustomer.p1')}
        </Typography>
        </Jump>
        <div className={"ctn_login"}> 
          <div className={clsx(classes.form, 'login_phone')} noValidate>
            <div className='input_icon'>
            <img src='https://i.ibb.co/SPhkKPB/call.png' alt="icon phone"/>
            <Input   
              value={phone}
              type="phone"
              placeholder="06 00  00 00  00"
              className="phone_input" 
              onChange={(event) => setPhone(event.target.value)}
              label={trans('phone')}
              onFocus={() => {
                (phone.length == 0) ? setPhone('0') :  setPhone(phone);
              }} 
            /> 
            </div>
            <div className='login_numpad'>  
            <NumPad
              value={phone}
              setValue={setPhone} 
              type='phone'
            /> 
            </div>
            <Button 
              type="button"
              disabled={!enablePhone}
              fullWidth 
              variant="outlined"
              color="primary"
              className="btn_submit" 
              onClick={onClick}
            >
              {trans('suivant')}
            </Button>
          </div>
        </div>
      </div>  
      </>
        </StoreContainer>
    </>
  );
};
export default InsertPhone;
