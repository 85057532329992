import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { trans } from 'itranslator';

const Content = ({ onClose, onSuccess, children, disableButtons,textLeft,textRight }) => {
  return (
    <>
      <DialogTitle disableTypography id="form-dialog-title"> 
      </DialogTitle>
      <DialogContent>
        {children || (
          <Typography variant="body1" align="center">
            {trans('confirmation.default.message')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disableButtons}
          onClick={(e) => {
            e.stopPropagation();
            onSuccess(e);
          }}
          variant="outlined"
          color="primary"
        >
          {trans(textLeft)}
        </Button>
        <Button
          disabled={disableButtons}
          color="primary"
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onClose(e);
          }}
        >
          {trans(textRight)}
        </Button>
      </DialogActions>
    </>
  );
};

Content.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool,
  children: PropTypes.any, 
  textLeft: PropTypes.string, 
  textRight: PropTypes.string, 
};

export default Content;
