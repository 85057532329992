import { useState } from 'react';
import axios, { } from 'myAxios'; 
import { hideProgress, showProgress } from 'utils/components/TopLinearProgress'; 

export const GET = 'get';
export const POST = 'post';
export const PATCH = 'patch';
export const DELETE = 'delete';
export const PUT = 'put';
export const ARRAY_METHODE = [POST, PATCH, DELETE, PUT];
/**
 *
 * @param {string} method
 * @param {string} path
 * @param {object} options
 * @param {boolean} options.withProgressBar
 * @param {boolean} options.showErrors
 * @returns
 */
const useAxios = (method, path, { withProgressBar = false } = {}) => {
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [performing, setPerforming] = useState(); 

  /**
   *
   * @param {object} param
   * @param {object} source - Axio cancel token source
   * @param {object} param.params
   * @param {object} param.body
   * @param {object} param.moreOptions
   * @returns
   */
  const call = async ({ source, params, body, path: newPath, moreOptions } = {}) => {
    try {
      const _path = newPath || path;
      setPerforming(true);
      withProgressBar && showProgress();
      let response = await _call(_path, params, body, method, source, moreOptions);

      setResponse(response); 
      return response;
    } catch (e) {
      setError(e); 
    } finally {
      setPerforming(false);
      withProgressBar && hideProgress();
    }
  };

  return {
    data: response?.data?.data,
    response,
    error,
    call,
    performing,
  };

  /**
   * @param {string} path
   * @param {object} params
   * @param {object} body
   * @param {string} method
   * @param {string} source.token
   * @param {object} options
   * @returns {Promise}
   *
   * @TODO include source & params can be in options
   */
  async function _call(path, params, body, method, source, options) {
    switch (true) {
      case [POST, PUT, PATCH].includes(method):
        return axios[method](path, body, {
          cancelToken: source?.token,
          params,
          ...options,
        });
      case [GET, DELETE].includes(method):
        return axios[method](path, {
          cancelToken: source?.token,
          data: body,
          params,
          ...options,
        });
      default:
        throw new Error(`Method ${method} is not supported`);
    }
  }

  /*function getErrorMessage(code) {
    switch (code) {
      case 400:
        return 'Paramètre manquant';
      case 406:
        return "La quantitée demandée n'est pas disponible en stock";
      default:
        return errorMessage;
    }
  }*/

  /*function handleError(e) {
    const error = e?.response?.data?.exception || getErrorMessage(e?.response?.status);
    // Gestion d'erreur
    if (_.isString(error)) {
      enqueueSnackbar(error, { variant: 'error' });
    } else if (_.isObject(error)) {
      Object.values(error).forEach((e) => enqueueSnackbar(e, { variant: 'error' }));
    }
  }*/
};

export default useAxios;
